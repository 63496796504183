import React, { useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import { AnimatePresence } from 'framer-motion'
import PaymentDetails from './PaymentDetails'

const PaymentCard = ({ payment }) => {
  const [isActive, setIsActive] = useState(payment.is_active === 1)
  const [active, setActive] = useState(false)

  const toggleSwitch = () => {
    setIsActive(!isActive)
    // Optionally, handle the toggle action (e.g., update the backend)
  }

  return (
    <>
      {' '}
      <div className=" border-[2px] border-blueButton gap-3 py-4 px-8 w-1/4 rounded-lg flex flex-col justify-center items-center space-y-4">
        <div className="text-blueButton text-xl">{payment.title}</div>
        <div className="flex items-center space-x-4">
          <span
            className={`${isActive ? 'text-blueButton/60' : 'text-blueButton'}`}
          >
            OFF
          </span>
          <div
            className={`relative inline-block w-14 h-7 rounded-full border-blueButton border-[1px] align-middle select-none transition duration-200 ease-in-out ${
              isActive ? '' : ''
            }`}
            onClick={toggleSwitch}
          >
            <span
              className={`absolute left-0 -top-[0.5px] block w-6 h-6 mt-0.5 ml-0.5 rounded-full  shadow transform transition-transform duration-200 ease-in-out ${
                isActive ? 'translate-x-6 bg-darkBlue' : 'bg-gray'
              }`}
            />
          </div>
          <span
            className={`${isActive ? 'text-blueButton' : 'text-blueButton/60'}`}
          >
            ON
          </span>
        </div>
        <button
          onClick={() => setActive(true)}
          className="flex items-center space-x-2 mt-4 text-blueButton"
        >
          <img src={eye} alt="" />

          <span>View Details</span>
        </button>
      </div>
      <AnimatePresence>
        {active && (
          <PaymentDetails
            payment={payment}
            active={active}
            close={() => setActive(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default PaymentCard
