import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import arow from '../../assets/icons/arow.svg'
import eye from '../../assets/icons/eyeBlack.svg'
import eyel from '../../assets/icons/closedEye.svg'
import addSystemUser from '../../apis/addSystemUser'
import showAllPermissions from '../../apis/showAllPermissions'
import generateDeviceToken from '../../utils/generateDeviceToken'
import assignPermissionsToUser from '../../apis/assignPermissionsToUser'

const AddEmployee = ({ active, close }) => {
  const popupref = useRef(null)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [showCon, setShowCon] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [openedCategory, setOpenedCategory] = useState(null)

  const [userData, setUserData] = useState({
    email: '',
    password: '',
    passwordConfermation: '',
    firstName: '',
    lastName: '',
    type: 'Admin',
  })

  const getPermissions = useCallback(async () => {
    try {
      const allPermissions = await showAllPermissions()
      setPermissions(allPermissions)
    } catch (error) {
      console.error('Failed to fetch permissions', error)
    }
  }, [])

  useEffect(() => {
    getPermissions()
  }, [getPermissions])

  const handleAddUser = async (e) => {
    e.preventDefault()
    if (userData.password !== userData.passwordConfermation) {
      setErrorMsg('Passwords do not match')
    } else {
      const deviceToken = generateDeviceToken(
        userData.firstName,
        userData.email
      )
      const userPayload = {
        ...userData,
        device_token: deviceToken,
        is_active: 1,
      }

      try {
        setLoading(true)
        const response = await addSystemUser(userPayload)
        console.log(response)
        const userId = response.data.id
        await assignPermissionsToUser(selectedPermissions, userId)
        window.location.reload()
      } catch (error) {
        console.error('Failed to add user', error)
        setErrorMsg('Failed to add user')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleInputChange = (e) => {
    setErrorMsg('')
    const { name, value } = e.target
    setUserData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handlePermissionChange = (permission) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permission)
        ? prevPermissions.filter((perm) => perm !== permission)
        : [...prevPermissions, permission]
    )
  }

  const groupPermissionsByCategory = (permissions) => {
    return permissions.reduce((acc, permission) => {
      const { category } = permission
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(permission)
      return acc
    }, {})
  }

  const toggleCategory = (category) => {
    setOpenedCategory(openedCategory === category ? null : category)
  }

  const permissionsByCategory = groupPermissionsByCategory(permissions)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/2 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handleAddUser} className="w-full">
          <div className="w-full px-8 py-5 flex flex-col gap-8">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div className=" text-darkBlue ">Add New Employee</div>
              <img src={x} onClick={close} className="cursor-pointer" alt="" />
            </div>
            <div className=" flex gap-2 ">
              <div className=" flex flex-col gap-5 w-1/2">
                <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue">
                  <input
                    value={userData.firstName}
                    onChange={handleInputChange}
                    className=" w-full focus:outline-none"
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                  />
                </div>

                <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue">
                  <input
                    value={userData.lastName}
                    onChange={handleInputChange}
                    className=" w-full focus:outline-none"
                    placeholder="Last Name"
                    name="lastName"
                    id="lastName"
                  />
                </div>

                <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue">
                  <input
                    value={userData.email}
                    onChange={handleInputChange}
                    className=" w-full focus:outline-none"
                    placeholder="Email"
                    name="email"
                    id="email"
                    type="email"
                  />
                </div>
                <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue flex ">
                  <input
                    name="password"
                    onChange={handleInputChange}
                    value={userData.password}
                    type={show ? 'text' : 'password'}
                    placeholder="Password"
                    className=" w-11/12 focus:outline-none"
                    required
                  />
                  <img
                    src={show ? eye : eyel}
                    onClick={() => setShow(!show)}
                    alt=""
                    className="w-[5%] cursor-pointer"
                  />
                </div>

                <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue flex ">
                  <input
                    name="passwordConfermation"
                    onChange={handleInputChange}
                    value={userData.passwordConfermation}
                    type={showCon ? 'text' : 'password'}
                    placeholder="Password Confirmation"
                    className=" w-11/12 focus:outline-none"
                    required
                  />
                  <img
                    src={showCon ? eye : eyel}
                    onClick={() => setShowCon(!showCon)}
                    alt=""
                    className="w-[5%] cursor-pointer"
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-2 w-1/2 px-2 max-h-96 overflow-auto">
                <div>Permissions</div>
                <div className=" w-fit flex-col flex gap-3">
                  {Object.keys(permissionsByCategory).map((category) => (
                    <div className=" w-full" key={category}>
                      <div
                        className=" py-2 flex justify-between items-center gap-4 text-center rounded-lg w-full px-4 cursor-pointer bg-blueButton text-white"
                        onClick={() => toggleCategory(category)}
                      >
                        <span>{category}</span>
                        <img
                          src={arow}
                          alt=""
                          className=" w-4 h-4 aspect-square"
                        />
                      </div>
                      {openedCategory === category && (
                        <div className="flex flex-col gap-1 mt-2">
                          {permissionsByCategory[category].map(
                            (permission, index) => (
                              <label
                                key={permission.id}
                                className={`${
                                  selectedPermissions.includes(permission.name)
                                    ? ' bg-lightBlue text-white'
                                    : index % 2 === 0
                                    ? ' bg-gray/30'
                                    : ''
                                } ml-4 rounded-lg cursor-pointer text-darkBlue px-4 py-2`}
                              >
                                <input
                                  type="checkbox"
                                  className="hidden"
                                  checked={selectedPermissions.includes(
                                    permission.name
                                  )}
                                  onChange={() =>
                                    handlePermissionChange(permission.name)
                                  }
                                />
                                {permission.name}
                              </label>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {errorMsg && (
              <div className=" w-full flex justify-center items-center  p-2">
                <div className=" bg-red/25 p-1 text-red rounded">
                  {errorMsg}
                </div>
              </div>
            )}
          </div>
          <button
            disabled={loading || errorMsg}
            className="disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full"
          >
            Add
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default AddEmployee
