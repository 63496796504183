import React from 'react'

const Notification = ({ index, notification }) => {
  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }
  return (
    <div
      className={` w-full flex  justify-between  px-4 ${
        index % 2 !== 0 ? ' bg-gray/10 py-2' : ''
      } `}
    >
      <div className=" flex gap-4 ">
        <div className=" rounded-full w-16 items-center aspect-square text-white text-xl p- grid place-items-center bg-darkBlue">
          Logo
        </div>
        <div className=" flex flex-col justify-center">
          <div className=" text-xl font-bold text-darkBlue">
            {notification.subject}
          </div>
          <div className=" text-darkBlue">{notification.body}</div>
        </div>
      </div>
      <div>{formatDate(notification.created_at)}</div>
    </div>
  )
}

export default Notification
