import axios from 'axios'

const approveBalanceOrder = async (id) => {
  console.log(id)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/ApproveBalanceOrders/${id}`,
      { accept: 'true' },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default approveBalanceOrder
