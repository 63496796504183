import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import dots from '../../assets/icons/dots.svg'
import CardMenu from './CardMenu'

const Card = ({ card, index }) => {
  const [active, setActive] = useState(false)
  console.log('card :', card)

  return (
    <div className="relative w-full">
      <div
        className="flex flex-col max-h-[300px] items-center justify-center rounded-lg overflow-hidden"
        style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
      >
        <div className="w-full h-40 ">
          <img
            className="w-full h-full object-cover object-center"
            src={`${card.image}`}
            alt={card.title}
          />
        </div>
        <div className="flex flex-col justify-center items-center w-full p-3  gap-2 h-1/2">
          <div className="w-full text-start">{card.title}</div>
          <div className="flex w-full items-center gap-2 ">
            <div className=" text-darkBlue w-1/3">status</div>
            {card.is_active === 0 ? (
              <div className="bg-red/35 rounded px-2   text-center text-red">
                Not Available
              </div>
            ) : (
              <div className="bg-green/35 px-2 rounded text-center text-green">
                Available
              </div>
            )}
          </div>
          <div className=" w-full flex gap-2">
            <Link
              to={`card/${card.id}?name=${card.title}`}
              className="bg-blueButton text-white py-1 text-center rounded w-4/5"
            >
              View
            </Link>
            <div
              onClick={() => setActive(true)}
              className=" cursor-pointer bg-blueButton rounded flex items-center w-1/5 justify-center  "
            >
              <img src={dots} alt="" />
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {active && (
          <CardMenu
            card={card}
            active={active}
            close={() => setActive(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Card
