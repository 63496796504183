//import axios from 'axios'

const showAllPayments = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/payments`
  //     )
  //     return response.data
  //   } catch (error) {
  //     throw error
  //   }

  //fro now we send some dumy data
  return [
    {
      is_active: 1,
      title: 'payment 1',
      type: 'payment 1',
    },
    {
      is_active: 0,
      title: 'payment 2',
      type: 'payment 2',
    },
    {
      is_active: 1,
      title: 'payment 3',
      type: 'payment 3',
    },
    {
      is_active: 1,
      title: 'payment 4',
      type: 'payment 4',
    },
    {
      is_active: 1,
      title: 'payment 5',
      type: 'payment 5',
    },
    {
      is_active: 1,
      title: 'payment 6',
      type: 'payment 6',
    },
    {
      is_active: 1,
      title: 'payment 7',
      type: 'payment 7',
    },
  ]
}

export default showAllPayments
