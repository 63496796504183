import React, { useState } from 'react'
import usericon from '../assets/icons/user.svg'
import lock from '../assets/icons/lock.svg'
import eye from '../assets/icons/eyeBlack.svg'
import eyel from '../assets/icons/closedEye.svg'
import bg from '../assets/images/lines.png'
import login from '../apis/login'
import { useAuth } from '../hooks/AuthContext'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [show, setshow] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const { login: authLogin } = useAuth()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setError('')
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleLogin = async (event) => {
    event.preventDefault()

    try {
      setLoading(true)
      const result = await login(formData)
      authLogin(result)
      navigate('/')
    } catch (error) {
      setError('Wrong Credentials')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className=" w-screen h-screen bg-gradient-to-br from-darkBlue to-categoryLight ">
      <div
        className=" w-full h-full grid place-items-center bg-no-repeat "
        style={{ backgroundImage: `url(${bg})` }}
      >
        <form
          onSubmit={handleLogin}
          className=" lg:w-1/3 max-w-[400px]  flex flex-col justify-center items-center gap-8 p-8 bg-white rounded-xl overflow-hidden"
        >
          <div className=" text-blueButton text-2xl">Log In</div>
          <div className=" w-full flex gap-4 flex-col justify-center items-center">
            <div className="w-full rounded-xl p-3 gap-2 border-[1px] border-blueButton flex ">
              <img src={usericon} alt="" className="w-1/12" />
              <input
                name="email"
                value={formData.email}
                type="email"
                onChange={handleInputChange}
                placeholder="Email"
                className=" w-11/12 focus:outline-none"
                required
              />
            </div>

            <div className="w-full rounded-xl p-3 gap-2 border-[1px] border-blueButton flex ">
              <img src={lock} alt="" className="w-1/12" />
              <input
                name="password"
                onChange={handleInputChange}
                value={formData.password}
                type={show ? 'text' : 'password'}
                placeholder="password"
                className=" w-10/12 focus:outline-none"
                required
              />
              <img
                src={show ? eye : eyel}
                onClick={() => setshow(!show)}
                alt=""
                className="w-1/12 cursor-pointer"
              />
            </div>
          </div>
          {error && (
            <div className=" bg-red/30 p-1 rounded text-red">{error}</div>
          )}
          <button
            disabled={loading}
            type="submit"
            className=" flex gap-2 disabled:bg-blueButton/60 bg-blueButton transition-all duration-300 text-2xl hover:scale-105 text-white text-center w-full p-4 rounded-xl"
          >
            {loading && (
              <div
                style={{
                  borderColor: `#A9A9A9 transparent #A9A9A9 transparent`,
                }}
                className=" border-2 w-1/12 aspect-square animate-spin rounded-full "
              ></div>
            )}
            <span className={`${loading ? 'w-11/12' : 'w-full'}`}>Log In</span>
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
