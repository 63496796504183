import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import photo from '../../assets/icons/phot.svg'
import addCodesToPackage from '../../apis/addCodesToPackage'

const AddCode = ({ active, close, packageId }) => {
  const popupref = useRef(null)
  const [error, setError] = useState('')
  const [entryMethod, setEntryMethod] = useState(0)
  const [codeData, setCodeData] = useState({
    vendor: '',
    title: '',
    file: null,
  })
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleAddCodes = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('entry_method', entryMethod)
    formData.append('Vendor', codeData.vendor)

    if (entryMethod === 1 && codeData.file) {
      formData.append('file', codeData.file)
    } else if (entryMethod === 0) {
      formData.append('title', JSON.stringify(codeData.title.split(',')))
    }

    try {
      setLoading(true)
      await addCodesToPackage(packageId, formData)
      window.location.reload()
    } catch (error) {
      setError('Failed to add codes')
      console.error('Failed to add codes', error)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    setError('')
    const { name, value } = e.target
    setCodeData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const allowedTypes = [
      'application/vnd.ms-excel',
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]

    if (allowedTypes.includes(file.type)) {
      setCodeData((prevData) => ({
        ...prevData,
        file: file,
      }))
      setPreview(URL.createObjectURL(file))
      setError('')
    } else {
      setError('Please upload a valid Excel or CSV file.')
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setCodeData((prevData) => ({
      ...prevData,
      file: file,
    }))
    setPreview(URL.createObjectURL(file))
  }

  const clearFiles = (e) => {
    e.preventDefault()
    setPreview(null)
    setCodeData((prevData) => ({
      ...prevData,
      file: null,
    }))
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/2 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handleAddCodes} className="w-full">
          <div className="w-full px-8 py-5 flex flex-col gap-8">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Add New Codes</div>
              <img src={x} onClick={close} className="cursor-pointer" alt="" />
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => setEntryMethod(1)}
                className={`py-2 px-4  rounded ${
                  entryMethod === 1 ? 'bg-blueButton text-white' : 'bg-gray/30'
                }`}
              >
                Upload File
              </button>
              <button
                type="button"
                onClick={() => setEntryMethod(0)}
                className={`py-2 px-4 rounded ${
                  entryMethod === 0 ? 'bg-blueButton text-white' : 'bg-gray/30'
                }`}
              >
                Manual Input
              </button>
            </div>
            <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
              <input
                value={codeData.vendor}
                required
                onChange={handleInputChange}
                className="w-full focus:outline-none"
                placeholder="Vendor"
                name="vendor"
                id="vendor"
              />
            </div>
            {entryMethod === 1 ? (
              <div className="rounded-xl p-4 border-[1px] h-1/3 border-b-darkBlue">
                <label
                  htmlFor="file"
                  className="w-full cursor-pointer rounded-xl relative p-4"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <input
                    name="file"
                    id="file"
                    type="file"
                    onChange={handleFileChange}
                    className="hidden w-full focus:outline-none"
                  />
                  {preview ? (
                    <div className="w-full absolute top-2 right-2 z-10 flex justify-end items-center">
                      <img
                        onClick={clearFiles}
                        className="cursor-pointer"
                        src={x}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="flex w-full justify-center items-center">
                      Choose or Drop a File
                    </div>
                  )}
                  <img
                    src={preview || photo}
                    alt="Preview"
                    className={`mt-4 w-full h-28 ${
                      preview ? 'object-contain' : 'object-contain'
                    }`}
                  />
                </label>
              </div>
            ) : (
              <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                <input
                  value={codeData.title}
                  required
                  onChange={handleInputChange}
                  className="w-full focus:outline-none"
                  placeholder="Titles (comma separated)"
                  name="title"
                  id="title"
                />
              </div>
            )}
          </div>
          {error && (
            <div className=" flex justify-center items-center p-3">
              <div className=" bg-red/20 rounded p-2 text-red">{error}</div>
            </div>
          )}
          <button
            disabled={loading}
            className="disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full"
          >
            Add
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default AddCode
