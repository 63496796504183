import React, { useEffect, useState } from 'react'
import EmpolyeeRow from '../emplyees/EmpolyeeRow'
import ClientRow from './ClientRow'

const AccountTable = ({ data, type, filter }) => {
  const [rows, setRows] = useState({
    first: 'First Name',
    second: 'Last Name',
    third: 'Email',
    fourth: 'Status',
    fifth: 'Status',
  })

  useEffect(() => {
    if (type === 'employees') {
      setRows({
        first: 'First Name',
        second: 'Last Name',
        third: 'Email',
        fourth: 'Role',
        fifth: 'Status',
        sixth: 'Action',
      })
    } else {
      setRows({
        first: 'Client Name',
        second: 'Client Name',
        third: 'Email',
        fourth: 'Role',
        fifth: 'Status',
        sixth: 'Action',
      })
    }
  }, [type])

  return (
    <div className="w-full ">
      <div className="flex py-2  text-darkBlue sticky top-0 bg-lightBlue/10 z-10">
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.first}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.second}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.third}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.fourth}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.fifth}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.sixth}
        </div>
      </div>
      <div className="overflow-y-auto max-h-96">
        {type === 'employees'
          ? data?.map((row, index) => (
              <EmpolyeeRow
                key={index}
                index={index}
                row={row}
                filter={filter}
              />
            ))
          : data?.map((row, index) => (
              <ClientRow key={index} index={index} row={row} />
            ))}
      </div>
    </div>
  )
}

export default AccountTable
