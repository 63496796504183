import axios from 'axios'
const showAllTrashedEmpolyees = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/getAllTrashedSystemUser`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data.data
  } catch (error) {
    throw error
  }
}

export default showAllTrashedEmpolyees
