import React, { useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import pen from '../../assets/icons/pen.svg'
import trash from '../../assets/icons/trash.svg'
import undo from '../../assets/icons/undo.svg'
import { AnimatePresence } from 'framer-motion'
import EmployeeDetails from './EmployeeDetails'
import EditEmployee from './EditEmployee'
import DeletEmployee from './DeletEmployee'
import RestorEmployee from './RestorEmployee'
const EmpolyeeRow = ({ row, index, filter }) => {
  const [view, setView] = useState(false)
  const [edit, setEdit] = useState(false)
  const [restore, setRestore] = useState(false)
  const [delet, setDelet] = useState(false)

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex py-2`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.first_name}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.last_name}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.email}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.type}
        </div>
        <div className="flex-1 flex p-2  items-center justify-center  ">
          {row.is_active === 1 ? 'active' : 'inactive'}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex gap-3">
          <button
            onClick={() => setView(true)}
            className="w-6 h-6 aspect-square flex text-blueButton rounded hover:bg-blue-600"
          >
            <span>
              <img src={eye} alt="" />
            </span>
          </button>

          {filter === 'Active' ? (
            <button
              onClick={() => setEdit(true)}
              className=" w-6 h-6 flex border-[1px] justify-center items-center rounded-sm border-darkBlue text-blueButton  p-1 aspect-square hover:bg-blue-600"
            >
              <span>
                <img src={pen} alt="" />
              </span>
            </button>
          ) : (
            <button
              onClick={() => setRestore(true)}
              className=" w-6 h-6 flex border-[1px] justify-center items-center rounded-sm border-darkBlue text-blueButton  p-1 aspect-square hover:bg-blue-600"
            >
              <span>
                <img src={undo} alt="" />
              </span>
            </button>
          )}

          <button
            onClick={() => setDelet(true)}
            className=" w-6 h-6 flex  justify-center items-center text-blueButton   aspect-square hover:bg-blue-600"
          >
            <span>
              <img src={trash} alt="" />
            </span>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {view && (
          <EmployeeDetails
            empolyee={row}
            active={view}
            close={() => setView(false)}
          />
        )}

        {restore && (
          <RestorEmployee
            employee={row}
            active={restore}
            close={() => setRestore(false)}
          />
        )}

        {edit && (
          <EditEmployee
            employee={row}
            active={edit}
            close={() => setEdit(false)}
          />
        )}
        {delet && (
          <DeletEmployee
            filter={filter}
            employee={row}
            active={delet}
            close={() => setDelet(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default EmpolyeeRow
