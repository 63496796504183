import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import arow from '../../assets/icons/arow.svg'
import getUserPermissions from '../../apis/getUserPermissions'
import { Link } from 'react-router-dom'

const EmployeeDetails = ({ empolyee, active, close }) => {
  console.log(empolyee)
  const [permissionsByCategory, setPermissionsByCategory] = useState({})
  const [openedCategory, setOpenedCategory] = useState(null)
  const popupref = useRef(null)

  const getPermissions = useCallback(async () => {
    try {
      const userPermissions = await getUserPermissions(empolyee.id)

      const groupedPermissions = userPermissions.reduce((acc, permission) => {
        if (!acc[permission.category]) {
          acc[permission.category] = []
        }
        acc[permission.category].push(permission)
        return acc
      }, {})
      setPermissionsByCategory(groupedPermissions)
    } catch (error) {}
  }, [empolyee.id])

  useEffect(() => {
    getPermissions()
  }, [getPermissions])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  const toggleCategory = (category) => {
    setOpenedCategory(openedCategory === category ? null : category)
  }

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/2 min-h-10 h-72 overflow-hidden bg-white rounded-lg flex flex-col  items-center"
      >
        <div className="w-full px-4">
          <div className="flex justify-between items-center w-full p-3 border-b-[1px] border-b-darkBlue">
            <div>{`${empolyee.first_name} ${empolyee.last_name}`}</div>
            <div className="flex justify-center gap-3 items-center">
              <div
                onClick={close}
                className="cursor-pointer w-6 grid place-items-center h-6"
              >
                <img src={x} alt="Close" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center p-3">
          <div className="w-1/2 flex flex-col gap-6">
            <div className="flex w-full gap-3">
              <span>First Name :</span>
              <span>{empolyee.first_name}</span>
            </div>
            <div className="flex w-full gap-3">
              <span>Last Name :</span>
              <span>{empolyee.last_name}</span>
            </div>
            <div className="flex w-full gap-3">
              <span>Email :</span>
              <Link
                className="underline text-lightBlue"
                to={`mailto:${empolyee.email}`}
              >
                {empolyee.email}
              </Link>
            </div>
            <div className="flex w-full gap-3">
              <span>Roll :</span>
              <span>{empolyee.type}</span>
            </div>
          </div>
          <div className=" flex flex-col gap-2 w-1/2 px-2 max-h-52 overflow-auto">
            <span>Permissions :</span>
            <div className=" w-fit flex-col flex gap-3">
              {Object.keys(permissionsByCategory).map((category) => (
                <div className="w-full" key={category}>
                  <div
                    className=" py-2 flex justify-between items-center gap-4 text-center rounded-lg px-4 cursor-pointer bg-blueButton text-white"
                    onClick={() => toggleCategory(category)}
                  >
                    <span>{category}</span>
                    <img src={arow} alt="" className=" w-4 h-4 aspect-square" />
                  </div>
                  {openedCategory === category && (
                    <div className="flex flex-col mt-2 gap-1 ml-4">
                      {permissionsByCategory[category].map(
                        (permission, index) => (
                          <div
                            className={`${
                              index % 2 === 0 ? '' : 'bg-gray/30'
                            } p-1 rounded-lg`}
                            key={permission.id}
                          >
                            {permission.name}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default EmployeeDetails
