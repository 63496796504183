import React, { useCallback, useEffect, useState } from 'react'
import SearshFilter from '../components/shared/SearshFilter'
import Notification from '../components/notifications/Notification'
import showAllNotifications from '../apis/showAllNotifications'

const Notifications = () => {
  const [sortType, setSortType] = useState('alphabet')
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])
  const [filteredNotifications, setFilteredNotifications] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const notificationData = await showAllNotifications()
      setNotifications(notificationData)
    } catch (error) {
      console.error('Failed to fetch', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    let filtered = notifications

    if (searchTerm) {
      filtered = filtered.filter(
        (notification) =>
          notification.subject
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          notification.body.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    if (sortType === 'alphabet') {
      filtered.sort((a, b) =>
        a.body?.toLowerCase() > b.body?.toLowerCase() ? 1 : -1
      )
    }

    setFilteredNotifications(filtered)
  }, [notifications, searchTerm, sortType])

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Notifications</div>
      <SearshFilter
        type="notifications"
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className="h-full shadow rounded-xl pb-2 flex flex-col justify-between">
        {loading ? (
          <>loading ...</>
        ) : (
          <div className=" flex overflow-hidden w-full h-full ">
            <div className="w-full flex flex-col gap-3 max-h-[70vh] overflow-x-hidden overflow-y-auto items-center">
              {filteredNotifications.map((notification, index) => (
                <Notification
                  key={index}
                  index={index + 1}
                  notification={notification}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Notifications
