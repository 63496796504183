import React, { useEffect, useRef, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import photo from '../../assets/icons/phot.svg'
import showAllCategories from '../../apis/showAllCategories'
import showSubCategories from '../../apis/showSubCategories'
import addCard from '../../apis/addCard'
import getCountries from '../../apis/getCountries'

const AddCard = ({ parentId, active, close }) => {
  const popupref = useRef(null)
  const [error, setError] = useState('')
  const [cardData, setCardData] = useState({
    title: '',
    description: '',
    categoryIds: parentId ? [+parentId] : [],
    countryId: '',
  })
  const [imageFile, setImageFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [countries, setCountries] = useState([])

  const handelAddCard = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('title', cardData.title)
    formData.append('description', cardData.description)
    formData.append('category_id', `[${cardData.categoryIds}]`)
    formData.append('country_id', cardData.countryId)
    formData.append('is_active', 1)
    if (imageFile) {
      formData.append('images', imageFile)
    } else {
      setError('Add An Image')
      return
    }

    try {
      setLoading(true)
      const res = await addCard(formData)
      if (res.status === false) {
        console.log(res)
        setError(res.message)
      } else {
        window.location.reload()
      }
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const handelInputChange = (e) => {
    const { name, value } = e.target
    setCardData((prevData) => ({ ...prevData, [name]: value }))
    setError('')
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))
    setError('')
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))

    const inputElement = document.getElementById('image')
    inputElement.focus()
    inputElement.blur()
    setError('')
  }

  const clearFiles = (e) => {
    e.preventDefault()
    setPreview(null)
    setImageFile(null)
  }

  const fetchCategories = useCallback(async () => {
    try {
      const allCountries = await getCountries()
      setCountries(allCountries)
      const allCategories = await showAllCategories()
      const categoriesWithSubCategories = await Promise.all(
        allCategories.map(async (category) => {
          const subCategories = await showSubCategories(category.id)
          return { ...category, subCategories }
        })
      )
      setCategories(categoriesWithSubCategories)
    } catch (error) {}
  }, [])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  const handleCategoryChange = (id, hasSubCategories) => {
    if (hasSubCategories) return

    setCardData((prevData) => {
      const newCategoryIds = prevData.categoryIds?.includes(id)
        ? prevData.categoryIds.filter((categoryId) => categoryId !== id)
        : [...prevData.categoryIds, id]

      console.log('parent', cardData.categoryIds)
      return { ...prevData, categoryIds: newCategoryIds }
    })
  }

  const handleCountryChange = (e) => {
    setCardData({ ...cardData, countryId: e.target.value })
  }

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/2 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handelAddCard} className="w-full">
          <div className="w-full px-8 py-5 flex flex-col gap-8">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Add New Card {parentId}</div>
              <img src={x} onClick={close} className="cursor-pointer" alt="" />
            </div>
            <div className="flex gap-4 justify-center items-center">
              <div className="w-1/2 flex flex-col gap-4">
                <div className="rounded-xl p-4 border-[1px] h-1/3 border-b-darkBlue">
                  <label
                    htmlFor="image"
                    className="w-full cursor-pointer rounded-xl relative p-4"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      name="image"
                      id="image"
                      type="file"
                      onChange={handleFileChange}
                      className="hidden w-full focus:outline-none"
                    />
                    {preview ? (
                      <div className="w-full absolute top-2 right-2 z-10 flex justify-end items-center">
                        <img
                          onClick={clearFiles}
                          className="cursor-pointer"
                          src={x}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="flex w-full justify-center items-center">
                        Choose or Drop a File
                      </div>
                    )}
                    <img
                      src={preview || photo}
                      alt="Preview"
                      className={`  w-full h-28 ${
                        preview ? 'object-contain' : 'object-contain'
                      }`}
                    />
                  </label>
                </div>

                <div className="w-full h-56 overflow-auto rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <div className="flex flex-col gap-4">
                    {categories.map((cat) => (
                      <React.Fragment key={cat.id}>
                        <div className="text-gray font-bold">{cat.title}</div>
                        <div className="flex flex-wrap gap-2 border-b border-gray-300 pb-2 mb-2">
                          {cat.subCategories.length > 0 ? (
                            cat.subCategories.map((sub) => (
                              <div
                                key={sub.id}
                                className={`p-2 border rounded-lg cursor-pointer ${
                                  cardData.categoryIds?.includes(sub.id)
                                    ? 'bg-blueButton text-white'
                                    : 'text-gray-700 border-gray-300'
                                }`}
                                onClick={() =>
                                  handleCategoryChange(sub.id, false)
                                }
                              >
                                - {sub.title}
                              </div>
                            ))
                          ) : (
                            <div
                              className={`p-2 border rounded-lg cursor-pointer ${
                                cardData.categoryIds?.includes(cat.id)
                                  ? 'bg-blueButton text-white'
                                  : 'text-gray-700 border-gray-300'
                              }`}
                              onClick={() =>
                                handleCategoryChange(cat.id, false)
                              }
                            >
                              {cat.title}
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex flex-col gap-4">
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <input
                    value={cardData.title}
                    required
                    onChange={handelInputChange}
                    className="w-full focus:outline-none"
                    placeholder="Card Name"
                    name="title"
                    id="title"
                  />
                </div>
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <select
                    className="focus:outline-none w-full text-gray"
                    onChange={handleCountryChange}
                    value={cardData.countryId || 'all'}
                    name="countryName"
                    id="countryName"
                  >
                    <option
                      disabled={true}
                      className="disabled:text-gray text-blueButton"
                      value="all"
                    >
                      Select Country
                    </option>
                    {countries?.map((country) => (
                      <option
                        className="text-blueButton"
                        key={country.id}
                        value={country.id}
                      >
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full h-80  rounded-xl px-4 py-3 border-[1px] border-b-darkBlue flex flex-col justify-between">
                  <textarea
                    value={cardData.description}
                    required
                    name="description"
                    id="description"
                    onChange={handelInputChange}
                    className="w-full h-full focus:outline-none"
                    placeholder="Description"
                  />
                </div>
              </div>
            </div>
          </div>
          {error && (
            <div className=" w-full flex justify-center py-3 items-center">
              <span className=" bg-red/20 px-3 py-1 text-red rounded">
                {error}
              </span>
            </div>
          )}
          <button
            disabled={loading}
            className="disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full"
          >
            Add
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default AddCard
