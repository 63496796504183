// api.js
import axios from 'axios'

const removePermissionFromUser = async (id, systemUserId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/removePermissionsSystemUser`,
      null,
      {
        params: {
          id,
          systemUser_id: systemUserId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default removePermissionFromUser
