import axios from 'axios'

const respondToComplaint = async (id, responseText) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/respondToComplaint/${id}`,
      {
        Response_text: responseText,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default respondToComplaint
