import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import AccountTable from '../components/account/AccountTable'
import showAllEmpolyees from '../apis/showAllEmpolyees'
import showAllTrashedEmpolyees from '../apis/showAllTrashedEmpolyees'
import showNormalClients from '../apis/showNormalClients'
import showPosClients from '../apis/showPosClients'

const Account = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('Active')
  const [sortType, setSortType] = useState('creationDate')

  const query = new URLSearchParams(location.search)
  const type = query.get('type') || 'employees'

  const fetchData = useCallback(async () => {
    setLoading(true)
    if (type === 'employees') {
      try {
        if (filter === 'Active') {
          const employees = await showAllEmpolyees()
          setData(employees)
        } else {
          const trasgedemployees = await showAllTrashedEmpolyees()
          setData(trasgedemployees)
        }
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        if (filter === 'Active') {
          const posClients = await showPosClients()
          setData(posClients)
        } else {
          const normalClients = await showNormalClients()
          setData(normalClients)
        }
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    }
  }, [type, filter])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    let filtered = data
    if (searchTerm) {
      filtered = data.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    if (sortType === 'creationDate') {
      filtered.sort((a, b) => a.first_name.localeCompare(b.first_name))
    } else if (sortType === 'alphabet') {
      filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }

    setFilteredData(filtered)
  }, [data, filter, searchTerm, sortType])

  const handleTabClick = (newType) => {
    navigate(`/account-management?type=${newType}`)
  }

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Account Management</div>
      <SearshFilter
        type={type}
        filter={filter}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortType={sortType}
        setSortType={setSortType}
      />
      <div className="flex space-x-4 ">
        <button
          className={`py-2 px-4  ${
            type === 'employees'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue'
              : ' text-gray'
          }`}
          onClick={() => handleTabClick('employees')}
        >
          Employees
        </button>
        <button
          className={`py-2 px-4  ${
            type === 'clients'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue '
              : 'text-gray'
          }`}
          onClick={() => handleTabClick('clients')}
        >
          Clients
        </button>
      </div>
      <div className="">
        {loading ? (
          <div>loading ... </div>
        ) : (
          <AccountTable data={filteredData} filter={filter} type={type} />
        )}
      </div>
    </>
  )
}

export default Account
