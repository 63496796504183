import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import x from '../../../assets/icons/close.svg'
import defaultPack from '../../../assets/images/defaultPackageImage.webp'
import getPackageOrder from '../../../apis/getPackageOrder'
import showPackage from '../../../apis/showPackage'
import { Link } from 'react-router-dom'

const ViewPackageOrder = ({ active, close, order }) => {
  const popupref = useRef(null)
  const [orderData, setOrderData] = useState([])
  const [loading, setLoaidng] = useState(true)
  const [packageDetails, setPackageDetails] = useState([])

  // const fetchPackageDetails = async (packages) => {
  //   if (packages) {
  //     try {
  //       const packagePromises = packages?.map(async (pkg) => {
  //         const packageId = pkg.id

  //         const packageData = await showPackage(packageId)
  //         return packageData
  //       })
  //       const fetchedPackages = await Promise.all(packagePromises)
  //       console.log('packages to fetch ', fetchedPackages)
  //       setPackageDetails(fetchedPackages)
  //     } catch (error) {
  //       console.error('Failed to fetch package details:', error)
  //     }
  //   } else {
  //     setPackageDetails([])
  //   }
  // }

  useEffect(() => {
    const getOrder = async () => {
      try {
        const orderres = await getPackageOrder(order.id)
        console.log('order ', orderres)
        setOrderData(orderres[0])
        setPackageDetails(orderres[0].Package)
        // if (orderres[0]?.Package) {
        //   fetchPackageDetails(orderres[0].Package)
        // }
      } catch (error) {
        console.error(error)
      } finally {
        setLoaidng(false)
      }
    }
    getOrder()
  }, [order.id])

  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/2 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <div className="w-full">
          <div className=" w-full px-8 py-5 flex flex-col gap-8 ">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Package Order</div>
              <img src={x} onClick={close} className=" cursor-pointer" alt="" />
            </div>
            {loading ? (
              <div className=" w-full h-[40vh] grid place-items-center ">
                loading ...
              </div>
            ) : (
              <div className=" w-full flex items-start gap-2">
                <div className="w-1/2 flex-col gap-8 flex justify-center items-center">
                  <div className=" flex gap-2 w-full items-center">
                    <div className=" font-bold text-darkBlue"> Order Id :</div>
                    <div className="text-darkBlue">
                      {orderData.package_order_id}
                    </div>
                  </div>
                  <div className=" flex gap-2 w-full">
                    <div className=" font-bold text-darkBlue text-nowrap">
                      Status :
                    </div>
                    <div className="text-darkBlue">{orderData.status}</div>
                  </div>
                  <div className=" flex gap-2 w-full">
                    <div className=" font-bold text-darkBlue text-nowrap">
                      Client :
                    </div>
                    <div className="text-darkBlue">{order.client_name}</div>
                  </div>

                  <div className=" flex gap-2 w-full items-center">
                    <div className=" font-bold text-darkBlue">Total Price</div>
                    <div className=" text-darkBlue">
                      {parseFloat(orderData.amount).toFixed(2)}$
                    </div>
                  </div>
                  <div className=" flex gap-2 w-full items-center">
                    <div className=" font-bold text-darkBlue">
                      Creation Date :
                    </div>
                    <div className="text-darkBlue">
                      {formatDate(orderData.created_at)}
                    </div>
                  </div>
                </div>
                <div className="w-1/2 flex-col gap-4 flex justify-center items-center">
                  <div className=" flex flex-col gap-2 w-full">
                    <div className=" font-bold text-darkBlue"> Packages :</div>
                    <div className=" h-[40vh] w-full overflow-auto">
                      {packageDetails.length > 0 ? (
                        packageDetails.map((pkg, idx) => (
                          <Link
                            to={`/cards/card/${pkg.card_id}/package/${pkg.id}`}
                            key={idx}
                            className="w-full h-24 gap-2 flex rounded-lg shadow p-2 bg-gray/20"
                          >
                            <img
                              className="rounded  h-full"
                              src={pkg?.image ? pkg?.image : defaultPack}
                              alt=""
                            />
                            <div className=" flex flex-col ">
                              <div>{pkg.title}</div>
                            </div>
                          </Link>
                        ))
                      ) : (
                        <>no packages</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ViewPackageOrder
