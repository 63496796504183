import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import showAllCashOnDelivaryOrders from '../apis/showAllCashOnDelivaryOrders'
import showAllBalanceOrders from '../apis/showAllBalanceOrders'
import OrdersTable from '../components/orders/OrdersTable'
import showAllPackageOrders from '../apis/showAllPackageOrders'

const Orders = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [sortType, setSortType] = useState('alphabet')
  const query = new URLSearchParams(location.search)
  const Ordertype = query.get('type') || 'cash-on-delivery'

  const handleTabClick = (newType) => {
    navigate(`/orders?type=${newType}`)
  }

  const fetchData = useCallback(async () => {
    try {
      setData([])
      setLoading(true)
      let orders = []
      switch (Ordertype) {
        case 'cash-on-delivery':
          orders = await showAllCashOnDelivaryOrders()
          break
        case 'balance-order':
          orders = await showAllBalanceOrders()
          break
        case 'package-order':
          orders = await showAllPackageOrders()
          break
        default:
          orders = []
          setLoading(false)
      }
      setData(orders)
      setFilteredData(orders)
    } catch (error) {
      setData([])
      console.error('Failed to fetch orders', error)
    } finally {
      setLoading(false)
    }
  }, [Ordertype])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (!Array.isArray(data)) {
      setFilteredData([])
      return
    }

    const filtered = data.filter(
      (order) =>
        order.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.id && order.id.toString().includes(searchTerm))
    )

    let sorted = [...filtered]
    if (sortType === 'alphabet' && Ordertype === 'balance-order') {
      console.log('sort type ', sortType)
      sorted = sorted.sort(
        (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
      )
    } else if (sortType === 'alphabet' && Ordertype === 'package-order') {
      sorted = sorted.sort(
        (a, b) => parseFloat(b.total_price) - parseFloat(a.total_price)
      )
    } else if (sortType === 'creationDate') {
      sorted = sorted.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )
    } else {
      sorted = sorted.sort(
        (a, b) => parseFloat(b.total_price) - parseFloat(a.total_price)
      )
    }

    setFilteredData(sorted)
  }, [searchTerm, sortType, data, Ordertype])
  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Orders</div>
      <SearshFilter
        type={'order'}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortType={sortType}
        setSortType={setSortType}
      />
      <div className="flex space-x-4 ">
        <button
          className={`py-2 px-4  ${
            Ordertype === 'cash-on-delivery'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue'
              : ' text-gray'
          }`}
          onClick={() => handleTabClick('cash-on-delivery')}
        >
          Cash On Delivery
        </button>
        <button
          className={`py-2 px-4  ${
            Ordertype === 'balance-order'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue '
              : 'text-gray'
          }`}
          onClick={() => handleTabClick('balance-order')}
        >
          Balance Order
        </button>

        <button
          className={`py-2 px-4  ${
            Ordertype === 'package-order'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue '
              : 'text-gray'
          }`}
          onClick={() => handleTabClick('package-order')}
        >
          Package Order
        </button>
      </div>
      <div className="">
        {loading ? (
          <div>loading ... </div>
        ) : (
          <OrdersTable type={Ordertype} data={filteredData} />
        )}
      </div>
    </>
  )
}

export default Orders
