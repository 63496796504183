import axios from 'axios'

const addCategory = async (title) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/addCategory`,
      { title: title, is_active: 1 },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data.data
  } catch (error) {
    throw error
  }
}

export default addCategory
