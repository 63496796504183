import axios from 'axios'

const editPackage = async (id, packageData) => {
  for (let pair of packageData.entries()) {
    console.log(`edited ${pair[0]}: ${pair[1]}`)
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/updatePackage/${id}`,
      packageData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    )
    return response.data
  } catch (error) {
    console.error('Failed to edit package:', error)
    throw error
  }
}

export default editPackage
