import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import viewAllCards from '../apis/viewCards'
import Card from '../components/card/Card'

const Cards = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [filter, setFilter] = useState('All')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [cards, setCards] = useState([])
  const itemsPerPage = 10

  const query = new URLSearchParams(location.search)
  const categoryId = query.get('category_id')
  const categoryName = query.get('name')

  const fetchItems = useCallback(async () => {
    try {
      const data = await viewAllCards()
      if (categoryId) {
        // Filter the cards based on the category_id
        const filteredCards = data.filter((card) =>
          card.category_id.includes(parseInt(categoryId))
        )
        setCards(filteredCards)
      } else {
        setCards(data)
      }
    } catch (error) {
      console.error('Failed to fetch items', error)
    } finally {
      setLoading(false)
    }
  }, [categoryId])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const filteredItems = cards.filter((item) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && item.is_active === 1) ||
      (filter === 'Inactive' && item.is_active === 0)
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    return matchesFilter && matchesSearch
  })

  const sortedItems = filteredItems.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const displayedItems = sortedItems.slice(startIndex, endIndex)

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory) {
      navigate(
        `/cards?category_id=${selectedCategory.id}&name=${selectedCategory.title}`
      )
    } else {
      navigate('/cards')
    }
  }

  return (
    <>
      <div className="text-2xl text-blueButton">
        Cards {categoryName && ` for ${categoryName}`}
      </div>
      <SearshFilter
        type="Cards"
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onCategoryChange={handleCategoryChange}
        selectedCategoryId={categoryId}
      />
      <div className=" h-full shadow rounded-xl pb-2 pt-8 px-8 flex flex-col justify-between">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {loading ? (
            'loading...'
          ) : cards.length === 0 ? (
            <div>No Cards yet.</div>
          ) : (
            displayedItems.map((item) => <Card key={item.id} card={item} />)
          )}
        </div>
        <div className="flex gap-8 mt-4">
          <div className="text-gray">Page:</div>
          <div className="text-gray flex gap-4">
            {pageNumbers.map((number) => (
              <div
                key={number}
                onClick={() => handleClick(number)}
                className={`cursor-pointer ${
                  number === currentPage ? 'font-bold underline' : ''
                }`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Cards
