import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import pen from '../../assets/icons/pen.svg'
import delet from '../../assets/icons/trash.svg'
import eye from '../../assets/icons/eyeOutline.svg'
import closedeye from '../../assets/icons/closedEye.svg'
import stok from '../../assets/icons/stok.svg'
import EditPackage from './EditPackage'
import editPackage from '../../apis/editPackage'
import DeletPackage from './DeletPackage'

const PackageMenu = ({ active, close, pack }) => {
  const menuRef = useRef(null)
  const [editActive, setEditActive] = useState(false)
  const [deletActive, setDeletActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stockloading, setstockLoading] = useState(false)
  const [position, setPosition] = useState({
    bottom: 'auto',
    top: 'auto',
    right: '5%',
    left: 'auto',
  })

  useLayoutEffect(() => {
    if (menuRef.current) {
      const { bottom, right } = menuRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth
      const menuHeight = menuRef.current.offsetHeight
      const menuWidth = menuRef.current.offsetWidth

      // Get current scroll position
      const scrollY = window.scrollY
      const scrollX = window.scrollX

      let newPosition = { bottom: '-25%', right: '-10%' }

      // Check if the menu is near the bottom edge
      if (bottom + menuHeight > viewportHeight + scrollY) {
        newPosition.bottom = 'auto'
        newPosition.top = `${viewportHeight - bottom + scrollY}px`
      }

      // Check if the menu is near the right edge
      if (right + menuWidth > viewportWidth + scrollX) {
        newPosition.right = 'auto'
        newPosition.left = `${viewportWidth - right + scrollX}px`
      }

      setPosition(newPosition)
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  const toggleActive = async () => {
    setLoading(true)
    const newActive = pack.is_active === 1 ? 0 : 1
    const formData = new FormData()

    formData.append('is_active', newActive)
    await editPackage(pack.id, formData)
    try {
    } catch (error) {
    } finally {
      setLoading(false)
      window.location.reload()
    }
  }

  const ToggleStok = async () => {
    setstockLoading(true)
    const stok = pack.out_of_stock === 0 ? 1 : 0
    const formData = new FormData()

    formData.append('out_of_stock', stok)
    await editPackage(pack.id, formData)
    try {
    } catch (error) {
    } finally {
      setstockLoading(false)
      window.location.reload()
    }
  }

  return (
    <div ref={menuRef}>
      <motion.div
        className={`  w-[200px] z-40 rounded-xl flex flex-col bg-white shadow overflow-hidden absolute`}
        style={position}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.3 }}
      >
        <div
          className="flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray"
          onClick={() => setEditActive(true)}
        >
          <img src={pen} alt="" className="w-[13%]" />
          <div className="w-10/12 text-blueButton">Edit</div>
        </div>

        <div
          className="flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray"
          onClick={() => setDeletActive(true)}
        >
          <img src={delet} alt="" className="w-[13%]" />
          <div className="w-10/12 text-red">Delete</div>
        </div>

        {pack.is_active === 0 ? (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray`}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={eye} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>Show Package</div>
          </div>
        ) : (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray`}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={closedeye} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>Hide Package</div>
          </div>
        )}

        {pack.out_of_stock === 1 ? (
          <div
            onClick={ToggleStok}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 `}
          >
            {stockloading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={stok} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>In Stock</div>
          </div>
        ) : (
          <div
            onClick={ToggleStok}
            className={`${
              stockloading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 `}
          >
            {stockloading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={stok} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>Out Of Stock</div>
          </div>
        )}
      </motion.div>

      <AnimatePresence>
        {editActive && (
          <EditPackage
            active={editActive}
            close={() => setEditActive(false)}
            pack={pack}
          />
        )}
        {deletActive && (
          <DeletPackage
            active={deletActive}
            close={() => setDeletActive(false)}
            pack={pack}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PackageMenu
