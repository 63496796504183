import React, { useState } from 'react'
import categoryicon from '../../assets/icons/categoriesBlack.svg'
import dots from '../../assets/icons/dots.svg'
import eye from '../../assets/icons/eyeBlack.svg'
import { AnimatePresence } from 'framer-motion'
import CategotyMenu from './CategotyMenu'
import { Link } from 'react-router-dom'

const CategoryCard = ({ category, index }) => {
  const [active, setActive] = useState(false)
  return (
    <div className=" relative">
      <div
        className={`  py-6 overflow-hidden bg-blueButton flex flex-col gap-10 justify-between rounded-xl
       ${
         index === 0
           ? 'bg-categoryLight'
           : index === 2
           ? 'bg-categoryMid'
           : 'bg-blueButton'
       }
      w-full `}
      >
        <div className=" absolute right-0 rounded-r-2xl top-0 h-full bg-gray/20 w-1/3 z-0 rounded-tl-[100%] rounded-bl-[70%]"></div>
        <div className="w-full px-5 flex items-stretch justify-between ">
          <div className=" w-1/6 bg-white aspect-square rounded-full flex justify-center items-center p-3  ">
            <img className=" w-10" src={categoryicon} alt="" />
          </div>
          <div className=" w-2/4 flex justify-start items-center text-white text-start">
            {category.title}
          </div>
          <div
            onClick={() => setActive(true)}
            className="z-10  w-1/4 flex justify-end items-start h-full"
          >
            <img src={dots} className=" cursor-pointer" alt="" />
          </div>
        </div>
        <div className=" w-full flex pl-5 justify-between">
          <div className=" flex  justify-center gap-3 items-center">
            <div className=" text-white/85">Status : </div>
            <div className=" text-white text-sm">
              {category.is_active === 1 ? 'Available' : 'Not Available'}
            </div>
          </div>
          <Link
            to={`category/${category.id}?name=${category.title}`}
            className=" bg-white w-[30%] z-10 rounded-l-full p-2 items-center gap-2 flex "
          >
            <img src={eye} alt="" />
            <div className=" text-blueButton"> View</div>
          </Link>
        </div>
      </div>
      <AnimatePresence>
        {active && (
          <CategotyMenu
            category={category}
            active={active}
            close={() => setActive(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default CategoryCard
