import { useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import { AnimatePresence } from 'framer-motion'
import ViewPackageOrder from './cash/ViewPackageOrder'

const PackageRow = ({ row, index }) => {
  console.log('dddd', row)
  const [view, setView] = useState(false)
  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex py-2`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.id}
        </div>
        <div className="flex-2 p-2 items-center  flex">{row.status}</div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.client_name}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {parseFloat(row.total_price).toFixed(2)}
        </div>
        <div className="flex-1 flex p-2 items-center justify-center">
          {formatDate(row.created_at)}
        </div>
        <div className="flex-1 flex p-2 items-center justify-center">
          <button
            onClick={() => setView(true)}
            className="w-6 h-6 aspect-square flex text-blueButton rounded hover:bg-blue-600"
          >
            <span>
              <img src={eye} alt="" />
            </span>
          </button>
        </div>
      </div>

      <AnimatePresence>
        {view && (
          <ViewPackageOrder
            active={view}
            close={() => setView(false)}
            order={row}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default PackageRow
