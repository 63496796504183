import { createContext, useContext, useState, useEffect } from 'react'
import getUserSessions from '../apis/getUserSessions'

const AuthContext = createContext({})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem('isAuthenticated')
  })

  const [user, setUser] = useState(() => {
    const localUser = localStorage.getItem('admin')
    return localUser ? JSON.parse(localUser) : null
  })

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('isAuthenticated', 'true')
      localStorage.setItem('admin', JSON.stringify(user))
    } else {
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('admin')
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    const verifySession = async () => {
      try {
        const sessions = await getUserSessions()
        if (!sessions) {
          logout()
        }
      } catch (error) {
        console.error('Failed to fetch admin sessions:', error)
        logout()
      }
    }

    if (isAuthenticated) {
      verifySession()
    }
  }, [isAuthenticated])

  const login = (userData) => {
    setUser(userData)
    setIsAuthenticated(true)
  }

  const logout = () => {
    setUser(null)
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
