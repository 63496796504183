import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import pen from '../../assets/icons/pen.svg'
import undo from '../../assets/icons/undo.svg'
import x from '../../assets/icons/close.svg'
import del from '../../assets/icons/trash.svg'
const PaymentDetails = ({ payment, active, close }) => {
  const popupref = useRef(null)
  const [edit, setEdit] = useState(false)
  const [form, setForm] = useState({
    title: payment.title,
    type: payment.type,
  })

  const handelInputChange = (e) => {
    const { name, value } = e.target

    setForm((prevData) => ({ ...prevData, [name]: value }))
  }

  const handelEdit = () => {
    if (edit) {
      setForm({
        title: payment.title,
        type: payment.type,
      })
      setEdit(!edit)
    } else {
      setEdit(!edit)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form className=" w-full ">
          <div className=" w-full px-4">
            <div className=" flex justify-between items-center w-full p-3 border-b-[1px] border-b-darkBlue">
              <div>Method Details</div>
              <div className=" flex justify-center gap-3 items-center">
                <div className=" cursor-pointer w-6 grid place-items-center h-6 border-[1px] border-red rounded  ">
                  <img src={del} alt="" />
                </div>
                <div
                  onClick={handelEdit}
                  className="cursor-pointer w-6 grid place-items-center p-1 h-6 border-[1px] border-darkBlue rounded "
                >
                  <img src={!edit ? pen : undo} alt="" />
                </div>
                <div
                  onClick={close}
                  className="cursor-pointer w-6 grid place-items-center h-6  "
                >
                  <img src={x} alt="" />
                </div>
              </div>
            </div>
            <div className=" flex flex-col w-full gap-6 justify-center py-6 items-center">
              <div className=" w-full border-[1px] border-darkBlue rounded p-3 ">
                <input
                  onChange={handelInputChange}
                  name="title"
                  id="title"
                  disabled={!edit}
                  value={form.title}
                  className=" disabled:bg-transparent w-full focus:outline-none"
                  placeholder="Payment Nmae"
                />
              </div>
              <div className=" w-full border-[1px] border-darkBlue rounded p-3 ">
                <input
                  onChange={handelInputChange}
                  name="type"
                  id="type"
                  disabled={!edit}
                  value={form.type}
                  className=" disabled:bg-transparent w-full focus:outline-none"
                  placeholder="Category Nmae"
                />
              </div>
            </div>
          </div>
          {edit && (
            <button className=" disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full ">
              Edit
            </button>
          )}
        </form>
      </motion.div>
    </motion.div>
  )
}

export default PaymentDetails
