import React, { useState } from 'react'
import SearshFilter from '../components/shared/SearshFilter'
import curentimg from '../assets/icons/curentBalance.svg'
import inimg from '../assets/icons/inBalance.svg'
import OUTimg from '../assets/icons/outBalance.svg'

const Balance = () => {
  const [formData, setFormData] = useState({
    accountNumber: '',
    balanceValue: '',
    archivingMethod: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(formData)
    // Handle form submission logic here
  }

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Balance</div>
      <SearshFilter type="balance" />
      <div className="h-full shadow rounded-xl pb-2 flex flex-col justify-between">
        <div className="w-full flex flex-col gap-16 items-center">
          <div className="flex w-full px-8 justify-between items-center">
            <div className="flex items-center gap-2">
              <div className="aspect-square">
                <img src={curentimg} alt="" />
              </div>
              <div>
                <div className="text-darkBlue text-xl font-bold">
                  Current Balance
                </div>
                <div className="text-darkBlue">3500$</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="aspect-square">
                <img src={inimg} alt="" />
              </div>
              <div>
                <div className="text-darkBlue text-xl font-bold">
                  Incoming Daily Balance
                </div>
                <div className="text-darkBlue">3500$</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="aspect-square">
                <img src={OUTimg} alt="" />
              </div>
              <div>
                <div className="text-darkBlue text-xl font-bold">
                  Daily Balance Transferred
                </div>
                <div className="text-darkBlue">3500$</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-8 px-8">
            <div className="text-darkBlue font-bold text-xl">
              Balance Transfer
            </div>
            <form className="w-1/3 gap-5 flex flex-col" onSubmit={handleSubmit}>
              <div className="w-full rounded-xl p-3 border-[1px] border-b-darkBlue">
                <input
                  className="w-full focus:outline-none"
                  placeholder="Account Number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full rounded-xl p-3 border-[1px] border-b-darkBlue">
                <input
                  className="w-full focus:outline-none"
                  placeholder="Balance Value"
                  name="balanceValue"
                  value={formData.balanceValue}
                  onChange={handleChange}
                />
              </div>
              <div className="flex w-full justify-between items-center">
                <label className="text-gray-700">Archiving Method</label>
                <div className="flex items-center mt-2">
                  <label className="mr-4 flex items-center">
                    <input
                      type="radio"
                      name="archivingMethod"
                      value="Paid"
                      checked={formData.archivingMethod === 'Paid'}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Paid
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="archivingMethod"
                      value="Debt"
                      checked={formData.archivingMethod === 'Debt'}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Debt
                  </label>
                </div>
              </div>
              <button className="bg-blueButton w-full py-3 rounded-lg text-white">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Balance
