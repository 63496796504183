import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import dots from '../../assets/icons/dotsblack.svg'
import eye from '../../assets/icons/eyewhite.svg'
import PackageMenu from './PackageMenu'

const PackageCard = ({ card, pack, index }) => {
  const [active, setActive] = useState(false)

  return (
    <div className="relative ">
      <div
        className="flex w-full items-center justify-center  rounded-lg overflow-hidden"
        style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
      >
        <div className=" p-5 w-2/5 ">
          <img
            src={pack.image || card.image}
            alt=""
            className=" w-full rounded aspect-square"
          />
        </div>
        <div className="w-3/5 py-2 flex flex-col">
          <div className=" gap-1 w-full flex flex-col pr-4">
            <div className="flex justify-between">
              <div>{pack.title}</div>
              <div onClick={() => setActive(true)}>
                <img src={dots} alt="" className=" cursor-pointer" />
              </div>
            </div>
            {pack.price_client !== pack.price_pos_client ? (
              <div className=" flex gap-6">
                <div className=" line-through">{pack.price_client}$</div>
                <div>{pack.price_pos_client}$</div>
              </div>
            ) : (
              <div className="  flex gap-6">
                <div>{pack.price_pos_client}$</div>
              </div>
            )}

            <div className="w-full flex gap-4 p-2 pl-0">
              {pack.is_active === 1 ? (
                <div className="bg-green/35 px-2 rounded text-center text-green">
                  Available
                </div>
              ) : (
                <div className="bg-red/35 px-1 text-sm rounded text-center text-red">
                  Un Available
                </div>
              )}

              {pack.out_of_stock === 0 ? (
                <div className="bg-green/35 px-2 rounded text-center text-green">
                  In Stok
                </div>
              ) : (
                <div className="bg-red/35 px-1 rounded text-sm text-center text-red">
                  Out Of Stok
                </div>
              )}
            </div>
          </div>
          <div className=" flex w-full justify-between items-center ">
            <div className=" text-darkBlue">{`Quantity: ${pack.count}`}</div>
            <Link
              to={`package/${pack.id}?card_name=${card.title}`}
              className=" bg-blueButton  w-1/2 z-10 rounded-l-full p-2 items-center gap-2 flex "
            >
              <img src={eye} alt="" />
              <div className=" text-white pr-3">View</div>
            </Link>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {active && (
          <PackageMenu
            pack={pack}
            active={active}
            close={() => setActive(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PackageCard
