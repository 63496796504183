import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import { Link } from 'react-router-dom'

const ClientDetails = ({ client, active, close }) => {
  const popupref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/3 min-h-10 h-72 overflow-hidden bg-white rounded-lg flex flex-col  items-center"
      >
        <div className="w-full px-4">
          <div className="flex justify-between items-center w-full p-3 border-b-[1px] border-b-darkBlue">
            <div>{`${client.first_name} ${client.last_name}`}</div>
            <div className="flex justify-center gap-3 items-center">
              <div
                onClick={close}
                className="cursor-pointer w-6 grid place-items-center h-6"
              >
                <img src={x} alt="Close" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center p-3">
          <div className="w-full flex flex-col gap-6">
            <div className="flex w-full gap-3">
              <span>First Name :</span>
              <span>{client.first_name}</span>
            </div>
            <div className="flex w-full gap-3">
              <span>Last Name :</span>
              <span>{client.last_name}</span>
            </div>
            <div className="flex w-full gap-3">
              <span>Email :</span>
              <Link
                className="underline text-lightBlue"
                to={`mailto:${client.email}`}
              >
                {client.email}
              </Link>
            </div>
            <div className="flex w-full gap-3">
              <span>Roll :</span>
              <span>{client.type === 'pos' ? 'wholesaler' : 'retailer'}</span>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ClientDetails
