import axios from 'axios'

const addSubCategory = async ({ title, isActive, parentId, imageFile }) => {
  const formData = new FormData()
  formData.append('title', title)
  formData.append('is_active', isActive)
  formData.append('parent_id', parentId)
  formData.append('images', imageFile)

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/addSubCategory`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default addSubCategory
