import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import DeletCode from './DeletCode'
import trash from '../../assets/icons/trash.svg'

const CodeRow = ({ code, index }) => {
  const [delet, setDelet] = useState(false)

  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }
  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} py-2 flex`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {code.title}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {code.status}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {formatDate(code.created_at)}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex gap-4 ">
          <div
            onClick={() => setDelet(true)}
            className=" w-7 h-7 flex cursor-pointer justify-center items-center"
          >
            <img src={trash} alt="" className=" w-full" />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {delet && (
          <DeletCode active={delet} close={() => setDelet(false)} code={code} />
        )}
      </AnimatePresence>
    </>
  )
}

export default CodeRow
