import ComplaintRow from './ComplaintRow'

const ComplaintTable = ({ data }) => {
  return (
    <div className="w-full ">
      <div className="flex py-2  text-darkBlue sticky top-0 bg-lightBlue/10 z-10">
        <div className="flex-1 p-2 items-center justify-center flex">
          Complaint Id
        </div>
        <div className="flex-1 p-2 items-center  flex">Message</div>
        <div className="flex-1 p-2 items-center justify-center flex">
          Client
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          Creation Date
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          Status
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          Actions
        </div>
      </div>
      <div className="overflow-y-auto max-h-96">
        {data?.map((row, index) => (
          <ComplaintRow key={index} index={index} row={row} />
        ))}
      </div>
    </div>
  )
}

export default ComplaintTable
