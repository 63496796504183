import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import showPackage from '../apis/showPackage'
import PackageDetailes from '../components/package/PackageDetailes'
import showPackageCodes from '../apis/showPackageCodes'
import CodeRow from '../components/code/CodeRow'

const Package = () => {
  const { packageId } = useParams()
  const navigate = useNavigate()
  const [filter, setFilter] = useState('All')
  const [stockStatus, setStockStatus] = useState('all')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)
  const [pack, setPack] = useState([])
  const [codes, setCodes] = useState([])

  const fetchItems = useCallback(async () => {
    try {
      const packageData = await showPackage(packageId)
      const codesData = await showPackageCodes(packageId)
      console.log(codesData)
      setPack(packageData)
      setCodes(codesData)
    } catch (error) {
      setCodes([])
    } finally {
      setLoading(false)
    }
  }, [packageId])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const filteredCodes = codes?.filter((item) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && item.status === 'active') ||
      (filter === 'Inactive' && item.status === 'rejected')
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())

    return matchesFilter && matchesSearch
  })

  const sortedCodes = filteredCodes?.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory) {
      navigate(
        `/cards?category_id=${selectedCategory.id}&name=${selectedCategory.title}`
      )
    } else {
      navigate('/cards')
    }
  }

  return (
    <>
      <div className="text-2xl text-blueButton">{pack.title} package</div>
      <SearshFilter
        type="code"
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onCategoryChange={handleCategoryChange}
        stockStatus={stockStatus}
        setStockStatus={setStockStatus}
      />
      <div className="h-full shadow rounded-xl pb-2 pt-2 px-4 flex flex-col justify-between">
        <div className="w-full flex flex-wrap gap-3 items-center">
          {loading ? (
            'loading...'
          ) : (
            <>
              <PackageDetailes pack={pack} />
              <div className="w-full ">
                <div className="flex py-2  text-darkBlue sticky top-0 bg-lightBlue/10 z-10">
                  <div className="flex-1 p-2 items-center justify-center flex">
                    Code
                  </div>
                  <div className="flex-1 p-2 items-center justify-center flex">
                    status
                  </div>
                  <div className="flex-1 p-2 items-center justify-center flex">
                    creation Date
                  </div>
                  <div className="flex-1 p-2 items-center justify-center flex">
                    Actions
                  </div>
                </div>
                <div className="overflow-y-auto max-h-64">
                  {sortedCodes?.map((code, index) => (
                    <CodeRow key={index} code={code} index={index} />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Package
