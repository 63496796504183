import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import x from '../../../assets/icons/close.svg'
import rejectBalanceOrder from '../../../apis/rejectBalanceOrder'

const RejectBalance = ({ active, close, order }) => {
  const popupref = useRef(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState('')

  const handelReject = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await rejectBalanceOrder(order.balance_order_id)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <div className="w-full">
          <div className=" w-full px-8 py-5 flex flex-col gap-8 ">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Reject Balance Order</div>
              <img src={x} onClick={close} className=" cursor-pointer" alt="" />
            </div>
            <div className=" items-center flex flex-col w-full gap-2 justify-center">
              <div className=" bg-red/15 rounded-full p-4">
                <img src={x} alt="" />
              </div>
              <div className=" text-center text-blueButton">
                Are You Sure You Want To Reject The Balance Order?
              </div>
            </div>

            {error && (
              <div className=" w-full flex justify-center py-3 items-center">
                <span className=" bg-red/20 px-3 py-1 text-red rounded">
                  {error}
                </span>
              </div>
            )}

            <div className=" w-full flex justify-center items-center gap-8">
              <button
                disabled={loading}
                onClick={handelReject}
                className=" disabled:bg-red/60 bg-red py-2 px-12 rounded-lg text-white "
              >
                Reject
              </button>

              <button
                onClick={close}
                className=" bg-gray py-2 px-12 rounded-lg text-white "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default RejectBalance
