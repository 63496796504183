import axios from 'axios'

const addCard = async (formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/addCard`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export default addCard
