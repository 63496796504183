import React from 'react'
import Sidepare from '../components/layouts/Sidepare'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div className=" flex justify-center items-center">
      <div className=" relative w-1/6">
        <Sidepare />
      </div>
      <main className=" w-5/6 flex justify-center items-center">
        <div className=" w-full h-screen pt-8 pb-4 px-5 flex  flex-col gap-8">
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default Dashboard
