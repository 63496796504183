import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import EditPackage from './EditPackage'
import { useLocation } from 'react-router-dom'

const PackageDetailes = ({ pack }) => {
  const [edit, setEdit] = useState(false)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const cardNmae = query.get('card_name')

  return (
    <>
      <div className=" w-full   p-4 rounded  bg-blueButton flex flex-col  ">
        <div className=" w-full flex gap-4 items-center">
          <div className=" w-1/12">
            <img
              src={pack.image}
              alt={pack.title}
              className=" w-full h-full rounded border-[1px] border-white "
            />
          </div>
          <div className=" w-11/12 flex flex-col gap-3 ">
            <div className=" flex gap-4 justify-between items-center ">
              <div className=" flex flex-col px-4 gap-2">
                <div className="  text-white">title</div>
                <div className=" text-xl text-white">{pack.title}</div>
              </div>

              <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                <div className="  text-white">Quantity</div>
                <div className=" text-xl text-white">{pack.count}</div>
              </div>

              {pack.price_client === pack.price_pos_client ? (
                <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                  <div className="  text-white">Price</div>
                  <div className=" text-xl text-white">
                    {pack.price_client} $
                  </div>
                </div>
              ) : (
                <>
                  <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                    <div className="  text-white">Retail Price</div>
                    <div className=" text-xl text-white">
                      {pack.price_client} $
                    </div>
                  </div>
                  <div className=" border-l-[1px] border-gray flex flex-col px-4 gap-2">
                    <div className="  text-white">wholesal Price</div>
                    <div className=" text-xl text-white">
                      {pack.price_pos_client} $
                    </div>
                  </div>
                </>
              )}

              <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                <div className="  text-white">Serial Number</div>
                <div className=" text-xl text-white">{pack.serial_number}</div>
              </div>

              <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                <div className="  text-white">Status</div>
                <div className=" text-xl text-white">
                  {pack.is_active === 1 ? 'Active' : 'Inactive'}
                </div>
              </div>

              <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                <div className="  text-white">Stock</div>
                <div className=" text-xl text-white">
                  {pack.out_of_stock === 0 ? 'In Stock' : 'Out Of Stock'}
                </div>
              </div>

              <div className="border-l-[1px] border-gray flex flex-col px-4 gap-2">
                <div className="  text-white">Card</div>
                <div className=" text-xl text-white">{cardNmae}</div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className=" w-full flex justify-end">
          <button
            className=" border-[1px] border-white rounded py-1 px-5 text-white hover:scale-110 duration-300 hover:bg-white hover:text-blueButton "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </div>
      </div>
      <AnimatePresence>
        {edit && (
          <EditPackage active={edit} close={() => setEdit(false)} pack={pack} />
        )}
      </AnimatePresence>
    </>
  )
}

export default PackageDetailes
