import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import showSingelCard from '../apis/showSingelCard'
import CardDetailes from '../components/card/CardDetailes'
import showCardPackages from '../apis/showCardPackages'
import PackageCard from '../components/package/PackageCard'
import getCategory from '../apis/getCategory'
import showSubCategoriesDetails from '../apis/showSubCategoriesDetails'

const SingelCard = () => {
  const { cardId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [filter, setFilter] = useState('All')
  const [stockStatus, setStockStatus] = useState('all')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [card, setCard] = useState([])
  const [categoryNames, setCategoryNames] = useState([])
  const [packages, setPackages] = useState([])
  const itemsPerPage = 3

  const query = new URLSearchParams(location.search)
  const categoryName = query.get('name')

  const fetchItems = useCallback(async () => {
    try {
      const cardData = await showSingelCard(cardId)
      console.log(cardData)
      // Rename categories to category_id
      const updatedCardData = {
        ...cardData,
        category_id: cardData.categories,
      }

      delete updatedCardData.categories

      const categoryTitles = await Promise.all(
        updatedCardData.category_id.map(async (id) => {
          let categoryData
          try {
            categoryData = await getCategory(id)
          } catch (error) {
            categoryData = await showSubCategoriesDetails(id)
          }
          return categoryData.title
        })
      )

      setCategoryNames(categoryTitles)
      setCard(updatedCardData)

      const cardPackages = await showCardPackages(cardId)
      setPackages(cardPackages)
    } catch (error) {
      setPackages([])
    } finally {
      setLoading(false)
    }
  }, [cardId])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const filteredPackages = packages?.filter((item) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && item.is_active === 1) ||
      (filter === 'Inactive' && item.is_active === 0)
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    const matchesStockStatus =
      stockStatus === 'all' ||
      (stockStatus === 'inStock' && item.out_of_stock === 0) ||
      (stockStatus === 'outOfStock' && item.out_of_stock === 1)
    return matchesFilter && matchesSearch && matchesStockStatus
  })

  const sortedPackages = filteredPackages?.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const displayedPackages = sortedPackages?.slice(startIndex, endIndex)

  const totalPages = Math.ceil(filteredPackages?.length / itemsPerPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  const handleCategoryChange = (selectedCategory) => {
    if (selectedCategory) {
      navigate(
        `/cards?category_id=${selectedCategory.id}&name=${selectedCategory.title}`
      )
    } else {
      navigate('/cards')
    }
  }

  return (
    <>
      <div className="text-2xl text-blueButton">
        card {categoryName && `  ${categoryName}`}
      </div>
      <SearshFilter
        type="packages"
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onCategoryChange={handleCategoryChange}
        stockStatus={stockStatus}
        setStockStatus={setStockStatus}
      />
      <div className="h-full shadow rounded-xl pb-2 pt-2 px-4 flex flex-col justify-between">
        <div className="w-full flex flex-wrap gap-3 items-center">
          {loading ? (
            'loading...'
          ) : (
            <>
              <CardDetailes card={card} categoryNames={categoryNames} />
              <div className="flex w-full justify-center pt-10 items-center">
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {!packages || packages?.length === 0 ? (
                    <div>No packages yet Add New</div>
                  ) : (
                    <>
                      {displayedPackages?.length === 0 ? (
                        <div>No matching packages</div>
                      ) : (
                        displayedPackages?.map((pack, index) => (
                          <PackageCard
                            key={index}
                            card={card}
                            pack={pack}
                            index={index}
                          />
                        ))
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex gap-8 mt-4">
          <div className="text-gray">Page:</div>
          <div className="text-gray flex gap-4">
            {pageNumbers.map((number) => (
              <div
                key={number}
                onClick={() => handleClick(number)}
                className={`cursor-pointer ${
                  number === currentPage ? 'font-bold underline' : ''
                }`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SingelCard
