import React, { useCallback, useEffect, useState } from 'react'
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom'
import showSubCategories from '../apis/showSubCategories'
import SearshFilter from '../components/shared/SearshFilter'
import SubCategoryCard from '../components/subcategory/SubCategoryCard'
import showCategoriesCards from '../apis/showCategoriesCards'

const SubCategory = () => {
  const { categoryId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search)
  const categoryName = query.get('name')
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [filter, setFilter] = useState('All')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')
  const [noItems, setNoItems] = useState(false)

  const fetchItems = useCallback(async () => {
    try {
      const cards = await showCategoriesCards(categoryId)

      if (cards.length > 0) {
        navigate(`/cards/?category_id=${categoryId}&name=${categoryName}`)
      } else {
        const subcategories = await showSubCategories(categoryId)
        if (subcategories.length === 0) {
          setNoItems(true)
        } else {
          setNoItems(false)
          setItems(subcategories)
        }
      }
    } catch (error) {
      console.error('Failed to fetch items', error)
    } finally {
      setLoading(false)
    }
  }, [categoryId, categoryName, navigate])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const startIndex = (currentPage - 1) * itemsPerPage

  const filteredItems = items.filter((item) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && item.is_active === 1) ||
      (filter === 'Inactive' && item.is_active === 0)
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    return matchesFilter && matchesSearch
  })

  const sortedItems = filteredItems.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const displayedItems = sortedItems.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  return (
    <>
      <div className="text-2xl text-blueButton">
        Sub categories for {categoryName}
      </div>
      <SearshFilter
        type="subcategory"
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className="h-full shadow rounded-xl pb-2 pt-8 px-8 flex flex-col justify-between">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {loading ? (
            'loading...'
          ) : noItems ? (
            <div className="col-span-2  ">
              No subcategories yet. Add new or go to{' '}
              <Link
                className="  underline text-lightBlue"
                to={`/cards?category_id=${categoryId}&name=${categoryName}`}
              >
                category cards
              </Link>
              .
            </div>
          ) : (
            displayedItems.map((item, index) => (
              <SubCategoryCard
                key={item.id}
                SubCategory={item}
                index={(index + 1) % 3}
              />
            ))
          )}
        </div>
        <div className="flex gap-8 mt-4">
          <div className="text-gray">Page:</div>
          <div className="text-gray flex gap-4">
            {pageNumbers.map((number) => (
              <div
                key={number}
                onClick={() => handleClick(number)}
                className={`cursor-pointer ${
                  number === currentPage ? 'font-bold underline' : ''
                }`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default SubCategory
