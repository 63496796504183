import React, { useCallback, useEffect, useState } from 'react'
import CategoryCard from '../components/category/CategoryCard'
import showAllCategories from '../apis/showAllCategories'
import SearshFilter from '../components/shared/SearshFilter'

const Categories = () => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const [filter, setFilter] = useState('All')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')

  const fetchCardDetails = useCallback(async () => {
    try {
      const allCategories = await showAllCategories()
      setCategories(allCategories)
    } catch (error) {
      console.error('Failed to fetch categories', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchCardDetails()
  }, [fetchCardDetails])

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const startIndex = (currentPage - 1) * itemsPerPage

  const filteredCategories = categories.filter((category) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && category.is_active === 1) ||
      (filter === 'Inactive' && category.is_active === 0)
    const matchesSearch = category.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    return matchesFilter && matchesSearch
  })

  const sortedCategories = filteredCategories.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const displayedCategories = sortedCategories.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  return (
    <>
      <div className="text-2xl text-blueButton">Cards Categories</div>
      <SearshFilter
        type={'category'}
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className="h-full shadow rounded-xl pb-2 pt-8 px-8 flex flex-col justify-between">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {loading
            ? 'loading...'
            : displayedCategories.map((category, index) => (
                <CategoryCard
                  key={category.id}
                  category={category}
                  index={(index + 1) % 3}
                />
              ))}
        </div>
        <div className="flex gap-8 mt-4">
          <div className="text-gray">Page:</div>
          <div className="text-gray flex gap-4">
            {pageNumbers.map((number) => (
              <div
                key={number}
                onClick={() => handleClick(number)}
                className={`cursor-pointer ${
                  number === currentPage ? 'font-bold underline' : ''
                }`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Categories
