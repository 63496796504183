import './App.css'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './components/layouts/ProtectedRoutes'
import Categories from './pages/Categories'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Cards from './pages/Cards'
import PaymentMethods from './pages/PaymentMethods'
import Reports from './pages/Reports'
import Notifications from './pages/Notifications'
import Balance from './pages/Balance'
import Settings from './pages/Settings'
import Account from './pages/Account'
import SubCategory from './pages/SubCategory'
import SingelCard from './pages/SingelCard'
import PublicRoute from './components/layouts/PublicRoute'
import Package from './pages/Package'
import Complaints from './pages/Complaints'
import { AuthProvider } from './hooks/AuthContext'
import Orders from './pages/Orders'
import SingelComplaint from './pages/SingelComplaint'

function App() {
  return (
    <AuthProvider>
      <div className="w-screen overflow-x-hidden">
        <Routes>
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<Categories />} />
            <Route path="category/:categoryId" element={<SubCategory />} />
            <Route path="/account-management" element={<Account />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/cards/card/:cardId" element={<SingelCard />} />
            <Route
              path="/cards/card/:cardId/package/:packageId"
              element={<Package />}
            />
            <Route path="/payment-methods" element={<PaymentMethods />} />
            <Route path="/complaints">
              <Route index element={<Complaints />} />
              <Route
                index
                path="complaint/:complaintId"
                element={<SingelComplaint />}
              />
            </Route>
            <Route path="/orders" element={<Orders />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/balance" element={<Balance />} />
            {/* <Route path="/messages" element={<Messages />}>
              <Route path="user/:userId" element={<Chat />} />
            </Route> */}
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </AuthProvider>
  )
}

export default App
