import axios from 'axios'

const logout = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/logout`,
      {}, // No body required
      {
        withCredentials: true, // This ensures cookies are sent with the request
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default logout
