import React, { useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import trash from '../../assets/icons/trash.svg'
import { AnimatePresence } from 'framer-motion'
import ClientDetails from '../emplyees/ClientDetails'
import Deletclient from '../emplyees/Deletclient'
const ClientRow = ({ row, index }) => {
  const [view, setView] = useState(false)
  const [delet, setDelet] = useState(false)

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.first_name}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.last_name}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.email}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.type}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.is_active === 1 ? 'active' : 'inactive'}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          <button
            onClick={() => setView(true)}
            className="bg-blue-500 flex gap-1 text-blueButton px-3 py-1 rounded hover:bg-blue-600"
          >
            <span>
              <img src={eye} alt="" />
            </span>
          </button>

          <button
            onClick={() => setDelet(true)}
            className=" w-6 h-6 flex  justify-center items-center text-blueButton   aspect-square hover:bg-blue-600"
          >
            <span>
              <img src={trash} alt="" />
            </span>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {view && (
          <ClientDetails
            client={row}
            active={view}
            close={() => setView(false)}
          />
        )}

        {delet && (
          <Deletclient
            client={row}
            active={delet}
            close={() => setDelet(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ClientRow
