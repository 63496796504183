import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import getComplaint from '../apis/getComplaint'
import { AnimatePresence } from 'framer-motion'
import RespondToComplaint from '../components/complaints/RespondToComplaint'
import getClient from '../apis/getClient'
import showAllUserComplaints from '../apis/showAllUserComplaints'

const SingelComplaint = () => {
  const { complaintId } = useParams()
  const [data, setData] = useState([])
  const [client, setClient] = useState([])
  const [userComplaints, setUserComplaints] = useState([])
  const [loading, setLoading] = useState(true)
  const [respond, setsetRespond] = useState(false)
  const fetchItems = useCallback(async () => {
    setLoading(true)
    try {
      const fetcheddata = await getComplaint(complaintId)
      const clientData = await getClient(fetcheddata.client_id)
      const complaintsByUser = await showAllUserComplaints(clientData.id)
      setData(fetcheddata)
      setClient(clientData)
      setUserComplaints(complaintsByUser)
    } catch (error) {
      console.error('Failed to fetch items', error)
    } finally {
      setLoading(false)
    }
  }, [complaintId])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  if (loading) {
    return <>loading ...</>
  }

  return (
    <>
      <div className="text-2xl text-blueButton">
        Complaint Text from Client {client.first_name} {client.last_name}
      </div>

      <div className=" flex h-full gap-2">
        <div className=" w-3/4 h-full shadow rounded-xl pb-2 pt-2 px-4 flex flex-col justify-between">
          <div className="h-2/3  flex flex-col justify-between">
            <div className="w-full  flex flex-wrap gap-3 items-center justify-between  p-4">
              {data.text}
            </div>
            <div className="border-t-[1px] p-4 border-t-gray">
              {data.Response_text ? (
                <div className=" flex flex-col gap-1 ">
                  <div className=" text-darkBlue text-lg">Response :</div>
                  <div className=" text-darkBlue ">{data.Response_text}</div>
                </div>
              ) : (
                <div>No Responds Yet</div>
              )}
            </div>
          </div>
          <div className="flex flex-col border-t-[1px] p-4 border-t-gray  gap-4 mt-4">
            <Link
              to={`mailto:${client.email}`}
              target="blank"
              className=" flex gap-1 items-center"
            >
              <div className=" text-darkBlue text-lg">email:</div>
              <div className=" text-darkBlue text-sm underline">
                {client.email}
              </div>
            </Link>

            <Link
              to={`tel:${client.mobile}`}
              target="blank"
              className=" flex gap-1 items-center"
            >
              <div className=" text-darkBlue text-lg">Phone:</div>
              <div className=" text-darkBlue text-sm underline">
                {client.mobile}
              </div>
            </Link>
            <button
              onClick={() => setsetRespond(true)}
              disabled={loading}
              className=" w-1/4 disabled:bg-darkBlue/50 rounded-lg bg-blueButton text-white text-center py-3 px-7 "
            >
              {data.Response_text ? 'Update Response' : 'Respond'}
            </button>
          </div>
        </div>
        <div className="w-1/4 p-3 shadow rounded-xl">
          <div className=" py-2 text-darkBlue text-xl border-b-[1px] border-b-gray">
            {client.first_name} {client.last_name} Complaints
          </div>
          <div className="  h-[90%] gap-2 w-full overflow-auto flex flex-col py-2 ">
            {userComplaints ? (
              userComplaints.map((complaint, index) => {
                console.log(complaint)
                if (Number(complaintId) === Number(complaint.id)) {
                  return <Fragment key={complaint.id}></Fragment>
                }
                return (
                  <Link
                    className={`p-2 text-darkBlue rounded-lg duration-300 hover:bg-blueButton hover:text-white even:bg-gray/20 `}
                    key={index}
                    to={`/complaints/complaint/${complaint.id}`}
                  >
                    {complaint.text}
                  </Link>
                )
              })
            ) : (
              <div>No Other Complaints</div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {respond && (
          <RespondToComplaint
            text={data.Response_text}
            active={respond}
            close={() => setsetRespond(false)}
            complaintId={complaintId}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default SingelComplaint
