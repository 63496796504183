import axios from 'axios'

const showPackage = async (id) => {
  console.log('id', id)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/viewPackage/${id}`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data.data
  } catch (error) {
    console.log('error ', error)
    throw error
  }
}

export default showPackage
