import axios from 'axios'

const assignPermissionsToUser = async (permissions, systemUserId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/AssignPermissionToSystemUser`,
      {
        permissions: permissions,
        systemUser_id: systemUserId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default assignPermissionsToUser
