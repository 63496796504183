import axios from 'axios'

const cancelCashOnDeliveryOrder = async (orderId, rejectionReason) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/CancelCashOnDeliveryOrder/${orderId}`,
      null,
      {
        params: {
          rejection_reason: rejectionReason,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export default cancelCashOnDeliveryOrder
