import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import x from '../../assets/icons/close.svg'
import photo from '../../assets/icons/phot.svg'
import editSubCategory from '../../apis/editSubCategory'

const EditSubCategory = ({ active, close, category }) => {
  const popupref = useRef(null)
  const [title, setTitle] = useState(category.title)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [imageFile, setImageFile] = useState(null)
  const [preview, setPreview] = useState(`${category.image}`)

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const clearFiles = (e) => {
    e.preventDefault()
    setPreview(null)
    setImageFile(null)
  }

  const handelEditSubCategory = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    if (title !== category.title) {
      formData.append('title', title)
    }
    if (imageFile) {
      formData.append('images', imageFile)
    }

    try {
      setLoading(true)
      await editSubCategory({
        id: category.id,
        formData,
      })
      window.location.reload()
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handelEditSubCategory} className="w-full">
          <div className="w-full px-8 py-5 flex flex-col gap-8">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Edit Sub Category</div>
              <img src={x} onClick={close} className="cursor-pointer" alt="" />
            </div>
            <div className="w-full rounded-xl p-4 border-[1px] border-b-darkBlue">
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full focus:outline-none"
                placeholder="Category Name"
              />
            </div>
            <label
              htmlFor="image"
              className="w-full cursor-pointer rounded-xl relative p-4 border-[1px] border-b-darkBlue"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                name="image"
                id="image"
                type="file"
                onChange={handleFileChange}
                className="hidden w-full focus:outline-none"
              />
              {preview ? (
                <div className="w-full absolute top-2 right-2 z-10 flex justify-end items-center">
                  <img
                    onClick={clearFiles}
                    className="cursor-pointer"
                    src={x}
                    alt=""
                  />
                </div>
              ) : (
                <div className="flex w-full justify-center items-center">
                  Choose or Drop a File
                </div>
              )}
              <img
                src={preview || photo}
                alt="Preview"
                className={`mt-4 w-full h-40 ${
                  preview ? 'object-cover' : 'object-contain'
                }`}
              />
            </label>
          </div>
          {error && (
            <div className=" w-full flex justify-center py-3 items-center">
              <span className=" bg-red/20 px-3 py-1 text-red rounded">
                {error}
              </span>
            </div>
          )}
          <button
            disabled={loading}
            className="disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full"
          >
            Edit
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default EditSubCategory
