import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import x from '../../assets/icons/close.svg'
import addCategory from '../../apis/addCategory'

const Addcategory = ({ active, close }) => {
  const popupref = useRef(null)
  const [title, setTitle] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const handelAddCategory = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      await addCategory(title)
      window.location.reload()
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const handelTitle = (e) => {
    setError('')
    setTitle(e.target.value)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])
  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handelAddCategory} className="w-full">
          <div className=" w-full px-8 py-5 flex flex-col gap-8 ">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Add New Category</div>
              <img src={x} onClick={close} className=" cursor-pointer" alt="" />
            </div>
            <div className=" w-full rounded-xl p-4 border-[1px] border-b-darkBlue">
              <input
                value={title}
                onChange={handelTitle}
                className=" w-full focus:outline-none"
                placeholder="Category Nmae"
              />
            </div>
          </div>
          {error && (
            <div className=" w-full flex justify-center py-3 items-center">
              <span className=" bg-red/20 px-3 py-1 text-red rounded">
                {error}
              </span>
            </div>
          )}
          <button
            disabled={loading}
            className=" disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full "
          >
            Add
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default Addcategory
