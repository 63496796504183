import axios from 'axios'

const getComplaint = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/viewComplaint/${id}`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response.data.data
  } catch (error) {
    throw error
  }
}

export default getComplaint
