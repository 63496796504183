import React, { useCallback, useEffect, useState } from 'react'
import SearshFilter from '../components/shared/SearshFilter'
import PaymentCard from '../components/payment/PaymentCard'
import showAllPayments from '../apis/showAllPayments' // Create this API function

const PaymentMethods = () => {
  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const [filter, setFilter] = useState('All')
  const [sortType, setSortType] = useState('creationDate')
  const [searchTerm, setSearchTerm] = useState('')

  const fetchPaymentDetails = useCallback(async () => {
    try {
      const allPayments = await showAllPayments()
      setPayments(allPayments)
    } catch (error) {
      console.error('Failed to fetch payment methods', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchPaymentDetails()
  }, [fetchPaymentDetails])

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const startIndex = (currentPage - 1) * itemsPerPage

  const filteredPayments = payments.filter((payment) => {
    const matchesFilter =
      filter === 'All' ||
      (filter === 'Active' && payment.is_active === 1) ||
      (filter === 'Inactive' && payment.is_active === 0)
    const matchesSearch = payment.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    return matchesFilter && matchesSearch
  })

  const sortedPayments = filteredPayments.sort((a, b) => {
    if (sortType === 'alphabet') {
      return a.title.localeCompare(b.title)
    } else if (sortType === 'creationDate') {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    return 0
  })

  const displayedPayments = sortedPayments.slice(
    startIndex,
    startIndex + itemsPerPage
  )

  const totalPages = Math.ceil(filteredPayments.length / itemsPerPage)
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  )

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Payment Methods</div>
      <SearshFilter
        type="payment"
        filter={filter}
        setFilter={setFilter}
        sortType={sortType}
        setSortType={setSortType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <div className="h-full shadow rounded-xl pb-2 pt-8 px-8 flex flex-col justify-between">
        <div className="w-full flex flex-wrap gap-x-14 gap-y-12 items-center justify-between">
          {loading
            ? 'loading...'
            : displayedPayments.map((payment, index) => (
                <PaymentCard key={payment.id} payment={payment} />
              ))}
        </div>
        <div className="flex gap-8 mt-4">
          <div className="text-gray">Page:</div>
          <div className="text-gray flex gap-4">
            {pageNumbers.map((number) => (
              <div
                key={number}
                onClick={() => handleClick(number)}
                className={`cursor-pointer ${
                  number === currentPage ? 'font-bold underline' : ''
                }`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentMethods
