import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SearshFilter from '../components/shared/SearshFilter'
import ReportsTable from '../components/reports/ReportsTable'
import showAllOrderReports from '../apis/showAllOrderReports'
import showAllBalanceReports from '../apis/showAllBalanceReports'

const Reports = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [sortType, setSortType] = useState('alphabet')
  const [loading, setLoading] = useState(true)
  const query = new URLSearchParams(location.search)
  const type = query.get('type') || 'order-reports'

  const handleTabClick = (newType) => {
    navigate(`/reports?type=${newType}`)
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    if (type === 'order-reports') {
      try {
        const res = await showAllOrderReports()
        setData(res)
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        const res = await showAllBalanceReports()
        setData(res)
      } catch (error) {
        console.error('Failed to fetch', error)
      } finally {
        setLoading(false)
      }
    }
  }, [type])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    let filtered = data.Details
    if (searchTerm) {
      filtered = data.Details.filter(
        (item) =>
          item.payment_method_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(item.client_id)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    }

    if (sortType === 'creationDate') {
      filtered?.sort(
        (a, b) => parseFloat(b.total_sum) - parseFloat(a.total_sum)
      )
    } else if (sortType === 'alphabet') {
      filtered?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    setFilteredData(filtered)
  }, [data, searchTerm, sortType])

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Reports</div>
      <SearshFilter
        type={'reports'}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortType={sortType}
        setSortType={setSortType}
      />
      <div className="flex space-x-4 -mb-2">
        <button
          className={`py-2 px-4  ${
            type === 'order-reports'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue '
              : 'text-gray'
          }`}
          onClick={() => handleTabClick('order-reports')}
        >
          Order Reports
        </button>
        <button
          className={`py-2 px-4  ${
            type === 'balance-reports'
              ? 'bg-blue-600 text-blueButton border-b-[2px] border-b-darkBlue'
              : ' text-gray'
          }`}
          onClick={() => handleTabClick('balance-reports')}
        >
          Balance Reports
        </button>
      </div>
      <div className=" ">
        <ReportsTable
          type={type}
          data={{ ...data, Details: filteredData }}
          loading={loading}
        />
      </div>
    </>
  )
}

export default Reports
