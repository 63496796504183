import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import pen from '../../assets/icons/pen.svg'
import delet from '../../assets/icons/trash.svg'
import eye from '../../assets/icons/eyeOutline.svg'
import closedeye from '../../assets/icons/closedEye.svg'
import EditCard from './EditCard'
import DeletCard from './DeletCard'
import editCard from '../../apis/editCard'

const CardMenu = ({ active, close, card }) => {
  console.log('card from menu', card)
  const menuRef = useRef(null)
  const [editActive, setEditActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deletActive, setDeletActive] = useState(false)
  const [position, setPosition] = useState({
    bottom: 'auto',
    top: 'auto',
    right: '5%',
    left: 'auto',
  })

  const toggleActive = async () => {
    setLoading(true)
    try {
      const cardData = { is_active: card.is_active === 1 ? 0 : 1 }
      await editCard(card.id, cardData)
      window.location.reload()
    } catch (error) {
      console.error('Error updating card:', error)
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (menuRef.current) {
      const { bottom, right } = menuRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth
      const menuHeight = menuRef.current.offsetHeight
      const menuWidth = menuRef.current.offsetWidth

      // Get current scroll position
      const scrollY = window.scrollY
      const scrollX = window.scrollX

      let newPosition = { bottom: '-40%', right: '5%' }

      // Check if the menu is near the bottom edge
      if (bottom + menuHeight > viewportHeight + scrollY) {
        newPosition.bottom = 'auto'
        newPosition.top = `${viewportHeight - bottom + scrollY}px`
      }

      // Check if the menu is near the right edge
      if (right + menuWidth > viewportWidth + scrollX) {
        newPosition.right = 'auto'
        newPosition.left = `${viewportWidth - right + scrollX}px`
      }

      setPosition(newPosition)
    }
  }, [active])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <div ref={menuRef}>
      <motion.div
        className={`  w-[200px] z-40 rounded-xl flex flex-col bg-white shadow overflow-hidden absolute`}
        style={position}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.3 }}
      >
        <div
          className="flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray"
          onClick={() => setEditActive(true)}
        >
          <img src={pen} alt="" className="w-[13%]" />
          <div className="w-10/12 text-blueButton">Edit</div>
        </div>

        <div
          className="flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray"
          onClick={() => setDeletActive(true)}
        >
          <img src={delet} alt="" className="w-[13%]" />
          <div className="w-10/12 text-red">Delete</div>
        </div>

        {card.is_active === 0 ? (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray`}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={eye} alt="" className=" w-[13%]" />
            )}
            <div className="w-10/12 text-blueButton">Show card</div>
          </div>
        ) : (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 `}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={closedeye} alt="" className=" w-[13%]" />
            )}
            <div className="w-10/12 text-blueButton">Hide card</div>
          </div>
        )}
      </motion.div>

      <AnimatePresence>
        {editActive && (
          <EditCard
            active={editActive}
            close={() => setEditActive(false)}
            card={card}
          />
        )}
        {deletActive && (
          <DeletCard
            active={deletActive}
            close={() => setDeletActive(false)}
            card={card}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default CardMenu
