import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import pen from '../../assets/icons/pen.svg'
import delet from '../../assets/icons/trash.svg'
import eye from '../../assets/icons/eyeOutline.svg'
import closedeye from '../../assets/icons/closedEye.svg'
import EditCategory from './EditCategory'
import DeletCategory from './DeletCategory'
import toggleActiveCategory from '../../apis/toggleActiveCategory'

const CategotyMenu = ({ active, close, category }) => {
  const menuRef = useRef(null)
  const [editActive, setEditActive] = useState(false)
  const [deletActive, setDeletActive] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  const toggleActive = async () => {
    setLoading(true)
    try {
      await toggleActiveCategory(category.id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div ref={menuRef}>
      <motion.div
        className=" w-[150px] z-40 rounded-xl flex flex-col bg-white shadow overflow-hidden absolute top-[20%] right-[5%]"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
      >
        <div className=" flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray">
          <img src={pen} alt="" className=" w-[13%]" />
          <div
            className=" w-10/12 text-blueButton"
            onClick={() => setEditActive(true)}
          >
            Edit
          </div>
        </div>

        <div className=" flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray">
          <img src={delet} alt="" className=" w-[13%]" />
          <div
            className=" w-10/12 text-red"
            onClick={() => setDeletActive(true)}
          >
            Delete
          </div>
        </div>

        {category.is_active === 0 ? (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray`}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={eye} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>Show Category</div>
          </div>
        ) : (
          <div
            onClick={toggleActive}
            className={`${
              loading ? ' bg-overlay' : ''
            } flex cursor-pointer gap-1 w-full p-2 border-b-[1px] border-gray`}
          >
            {loading ? (
              <div
                style={{
                  borderColor: `#fff transparent #fff transparent`,
                }}
                className=" border-2 w-[13%] aspect-square animate-spin rounded-full "
              ></div>
            ) : (
              <img src={closedeye} alt="" className=" w-[13%]" />
            )}
            <div className={` w-10/12 text-blueButton`}>Hide Category</div>
          </div>
        )}
      </motion.div>

      <AnimatePresence>
        {editActive && (
          <EditCategory
            active={editActive}
            close={() => setEditActive(false)}
            categoty={category}
          />
        )}
        {deletActive && (
          <DeletCategory
            active={deletActive}
            close={() => setDeletActive(false)}
            categoty={category}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default CategotyMenu
