import React, { useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import check from '../../assets/icons/check.svg'
import close from '../../assets/icons/close.svg'
import { AnimatePresence } from 'framer-motion'
import ViewCash from './cash/ViewCash'
import ApproveCash from './cash/ApproveCash'
import RejectCash from './cash/RejectCash'

const CachOnDelivaryRow = ({ row, index }) => {
  const [view, setView] = useState(false)
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)

  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex py-2`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.order_id}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.status}
        </div>

        <div className="flex-1 p-2 items-center justify-center flex">
          {row.client_name}
        </div>

        <div className="flex-1 p-2 items-center justify-center flex">
          {parseFloat(row.total_price).toFixed(2)}
        </div>

        <div className="flex-1 flex p-2  items-center justify-center  ">
          {formatDate(row.created_at)}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex gap-3">
          <button
            onClick={() => setView(true)}
            className="w-6 h-6 aspect-square flex text-blueButton rounded hover:bg-blue-600"
          >
            <span>
              <img src={eye} alt="" />
            </span>
          </button>
          <button
            onClick={() => setApprove(true)}
            className=" w-6 h-6 flex justify-center items-center rounded-sm  text-blueButton   aspect-square hover:bg-blue-600"
          >
            <span>
              <img src={check} alt="" />
            </span>
          </button>
          <button
            onClick={() => setReject(true)}
            className=" w-6 h-6 flex  justify-center items-center text-blueButton   aspect-square hover:bg-blue-600"
          >
            <span>
              <img src={close} alt="" />
            </span>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {view && (
          <ViewCash order={row} active={view} close={() => setView(false)} />
        )}
        {approve && (
          <ApproveCash
            order={row}
            active={approve}
            close={() => setApprove(false)}
          />
        )}
        {reject && (
          <RejectCash
            order={row}
            active={reject}
            close={() => setReject(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default CachOnDelivaryRow
