import React, { useEffect, useState } from 'react'
import ReportRow from './ReportRow'

const ReportsTable = ({ type, data, loading }) => {
  const [rows, setRows] = useState({
    first: 'Order Id',
    second: 'Claient Id',
    third: 'Payment Method',
    fourth: 'Status',
    fifth: 'Creation Date',
    sixth: 'Price',
  })

  useEffect(() => {
    if (type === 'order-reports') {
      setRows({
        first: 'Order Id',
        second: 'Claient Name',
        third: 'Payment Method',
        fourth: 'Status',
        fifth: 'Creation Date',
        sixth: 'Price',
      })
    } else {
      setRows({
        first: 'Order Id',
        second: 'Client Name',
        third: 'Payment Method',
        fourth: 'Status',
        fifth: 'Creation Date',
        sixth: 'total',
      })
    }
  }, [type])

  return (
    <div className="w-full ">
      <div className="flex py-2  text-darkBlue sticky top-0 bg-lightBlue/10 z-10">
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.first}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.second}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.third}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.fourth}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.fifth}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.sixth}
        </div>
      </div>
      <div className="overflow-y-auto h-96">
        {loading ? (
          <>loading ..</>
        ) : data?.Details?.length > 0 ? (
          data?.Details.map((row, index) => (
            <ReportRow key={index} index={index} row={row} />
          ))
        ) : (
          <>no Reports Yet</>
        )}
      </div>
      <div className=" w-full flex justify-end items-center">
        <div className=" bg-gray/10 text-darkBlue py-1  w-1/6 flex justify-center items-center px-3 ">
          totals sum:{data?.total_sum}
        </div>
      </div>
    </div>
  )
}

export default ReportsTable
