import React, { useEffect, useState } from 'react'
import CachOnDelivaryRow from './CachOnDelivaryRow'
import PackageRow from './PackageRow'
import BalanceOrder from './BalanceOrder'

const OrdersTable = ({ data, type }) => {
  const [rows, setRows] = useState({
    first: 'Order Id',
    second: 'status',
    third: 'Total Price',
    fourth: 'Creation Date',
    fifth: 'Actions',
  })

  useEffect(() => {
    if (type === 'cash-on-delivery') {
      setRows({
        first: 'Order Id',
        second: 'status',
        third: 'Client',
        fourth: 'Total Price',
        fifth: 'Creation Date',
        sixth: 'Actions',
      })
    } else if (type === 'balance-order') {
      setRows({
        first: 'Order Id',
        second: 'status',
        third: 'Total Price',
        fourth: 'Creation Date',
        fifth: 'Rejected',
        sixth: 'Action',
        seventh: 'Client',
      })
    } else {
      setRows({
        first: 'Order Id',
        second: 'status',
        third: 'Client Name',
        fourth: 'Total Price',
        fifth: 'Creation Date',
        sixth: 'Actions',
      })
    }
  }, [type])

  return (
    <div className="w-full">
      <div className="flex py-2 text-darkBlue sticky top-0 bg-lightBlue/10 z-10">
        <div className="flex-1 p-2 items-center justify-center  flex">
          {rows.first}
        </div>
        <div
          className={`${
            type === 'cash-on-delivery'
              ? 'flex-1'
              : type === 'package-order'
              ? 'flex-2 justify-start'
              : 'flex-1 justify-center'
          }
              p-2 items-center  flex`}
        >
          {rows.second}
        </div>

        {rows.seventh && (
          <div className="flex-1 justify-center p-2 items-center  flex">
            {rows.seventh}
          </div>
        )}

        <div className="flex-1 justify-center p-2 items-center  flex">
          {rows.third}
        </div>
        <div className="flex-1 p-2 items-center justify-center  flex">
          {rows.fourth}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {rows.fifth}
        </div>
        {rows.sixth && (
          <div className="flex-1 p-2 items-center justify-center flex">
            {rows.sixth}
          </div>
        )}
      </div>
      <div className="overflow-y-auto max-h-96">
        {type === 'cash-on-delivery'
          ? data?.map((row, index) => (
              <CachOnDelivaryRow key={index} index={index} row={row} />
            ))
          : type === 'balance-order'
          ? data?.map((row, index) => (
              <BalanceOrder key={index} index={index} row={row} />
            ))
          : data?.map((row, index) => (
              <PackageRow key={index} index={index} row={row} />
            ))}
      </div>
    </div>
  )
}

export default OrdersTable
