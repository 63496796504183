import React, { useState } from 'react'
import clock from '../assets/icons/clock.svg'
import pen from '../assets/icons/pen.svg'
import undo from '../assets/icons/undo.svg'
import sheald from '../assets/icons/sheald.svg'
import logout from '../apis/logout'
import { useAuth } from '../hooks/AuthContext'

const Settings = () => {
  const [downTime, setDownTime] = useState('')
  const [edit, setEdit] = useState(false)
  const [twoStep, setTwoStep] = useState(false)
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const { logout: authLogout } = useAuth()
  const handelTwoStep = () => {
    setTwoStep(!twoStep)
    setSave(!save)
  }

  const handelLogout = async () => {
    setLoading(true)
    try {
      await logout()
      authLogout()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="text-2xl text-blueButton">Settings</div>
      <div className=" h-full shadow rounded-xl pb-2 pt-8 px-8 flex flex-col justify-between">
        <div className="w-full flex flex-col flex-wrap gap-3 items-center">
          <form className=" border-b-[2px] pb-5 border-gray w-full flex justify-between items-center">
            <div className=" w-1/2 flex justify-between  items-center  gap-4 ">
              <div className=" flex items-center gap-4 ">
                <div className=" bg-gray/50 rounded-full p-2">
                  <img src={clock} alt="" />
                </div>
                <div className=" text-darkBlue text-xl">
                  Duration Of Downtime
                </div>
              </div>
              <div className=" bg-gray/10 w-1/3 flex justify-center items-center rounded p-2 ">
                <input
                  name="hours"
                  id="hours"
                  type="number"
                  disabled={!edit}
                  value={downTime}
                  onChange={(e) => setDownTime(e.target.value)}
                  placeholder="1"
                  focus={edit}
                  className="  bg-transparent w-1/3 disabled:bg-transparent focus:outline-none "
                />
                <label
                  htmlFor="hours"
                  className=" cursor-pointer w-2/3 text-darkBlue "
                >
                  Hours
                </label>
              </div>
            </div>
            <div className=" flex gap-8 h-full justify-between items-center">
              <div
                onClick={() => setEdit(!edit)}
                className=" shadow shadow-darkBlue/40 aspect-square h-full p-1 border-[1px] border-b-darkBlue rounded grid place-items-center cursor-pointer"
              >
                <img className="w-5" src={edit ? undo : pen} alt="" />
              </div>
              <button
                disabled={!edit}
                className=" disabled:bg-blueButton/80 h-full px-10 py-1 bg-blueButton rounded text-center text-white text-xl"
              >
                Save
              </button>
            </div>
          </form>
          <form className="  pb-5  w-full flex justify-between items-center">
            <div className="w-1/2 flex justify-between  items-center  gap-4 ">
              <div className=" flex items-center gap-4 ">
                <div className=" bg-gray/50 rounded-full p-2">
                  <img src={sheald} alt="" />
                </div>
                <div className=" text-darkBlue text-xl">
                  Two-Step Verification
                </div>
              </div>
              <div className="  w-1/3 flex justify-center items-center rounded p-2 ">
                <div className="flex items-center space-x-4">
                  <span
                    className={`${
                      twoStep ? 'text-blueButton/60' : 'text-blueButton'
                    }`}
                  >
                    OFF
                  </span>
                  <div
                    className={`relative inline-block w-14 h-7 rounded-full border-blueButton border-[1px] align-middle select-none transition duration-200 ease-in-out ${
                      twoStep ? '' : ''
                    }`}
                    onClick={handelTwoStep}
                  >
                    <span
                      className={`absolute left-0 -top-[0.5px] block w-6 h-6 mt-0.5 ml-0.5 rounded-full  shadow transform transition-transform duration-200 ease-in-out ${
                        twoStep ? 'translate-x-6 bg-darkBlue' : 'bg-gray'
                      }`}
                    />
                  </div>
                  <span
                    className={`${
                      twoStep ? 'text-blueButton' : 'text-blueButton/60'
                    }`}
                  >
                    ON
                  </span>
                </div>
              </div>
            </div>
            <div className=" flex gap-8 h-full justify-between items-center">
              {save && (
                <button className=" h-full px-10 py-1 bg-blueButton rounded text-center text-white text-xl">
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
        <div className=" w-full flex  ">
          <button
            disabled={loading}
            onClick={handelLogout}
            className=" flex gap-2 justify-center items-center py-2 px-5 disabled:bg-blueButton/60 bg-blueButton transition-all duration-300 text-xl hover:scale-105 text-white text-center   rounded-xl"
          >
            {loading && (
              <div
                style={{
                  borderColor: `#A9A9A9 transparent #A9A9A9 transparent`,
                }}
                className=" border-2 w-1/12 h-1/2 aspect-square animate-spin rounded-full "
              ></div>
            )}
            <span className={`${loading ? 'w-11/12 ' : 'w-full'}`}>
              Log Out
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default Settings
