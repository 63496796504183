const ReportRow = ({ row, index }) => {
  console.log(row)

  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.order_id}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.client_id}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.payment_method_name}
        </div>
        <div className="flex-1 p-2 items-center text-center justify-center flex">
          {row.status}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {formatDate(row.created_at)}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {parseFloat(row.total_sum).toFixed(2)}
        </div>
      </div>
    </>
  )
}

export default ReportRow
