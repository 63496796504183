import React, { useCallback, useEffect, useState } from 'react'
import eye from '../../assets/icons/eyeBlack.svg'
import { AnimatePresence } from 'framer-motion'
import EmployeeDetails from '../emplyees/EmployeeDetails'
import { Link } from 'react-router-dom'
import getClient from '../../apis/getClient'

const ComplaintRow = ({ row, index }) => {
  const [view, setView] = useState(false)
  const [clientName, setClientName] = useState('')

  const fetchClientName = useCallback(async () => {
    try {
      const response = await getClient(row.client_id)
      setClientName(`${response.first_name} ${response.last_name}`)
    } catch (error) {
      console.error('Failed to fetch client name', error)
    }
  }, [row.client_id])

  useEffect(() => {
    fetchClientName()
  }, [fetchClientName])

  const truncateText = (text) => {
    const words = text.split(' ')
    if (words.length > 5) {
      return `${words.slice(0, 5).join(' ')}...`
    }
    return text
  }

  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <>
      <div className={`${index % 2 === 0 ? '' : ' bg-gray/20'} flex`}>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.id}
        </div>
        <div className="flex-1 p-2 items-center flex">
          {truncateText(row.text)}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {clientName || 'loading...'}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {formatDate(row.created_at)}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          {row.status}
        </div>
        <div className="flex-1 p-2 items-center justify-center flex">
          <Link
            to={`complaint/${row.id}`}
            className="bg-blue-500 flex gap-1 text-blueButton px-3 py-1 rounded hover:bg-blue-600"
          >
            <span>
              <img src={eye} alt="" />
            </span>
            <span>View</span>
          </Link>
        </div>
      </div>
      <AnimatePresence>
        {view && (
          <EmployeeDetails
            empolyee={row}
            active={view}
            close={() => setView(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default ComplaintRow
