import React, { useEffect, useState } from 'react'
import EditCard from './EditCard'
import { AnimatePresence } from 'framer-motion'
import getCountries from '../../apis/getCountries'

const CardDetailes = ({ card, categoryNames }) => {
  console.log('card detalis ', card)
  const [edit, setEdit] = useState(false)
  const [countryName, setCountryName] = useState('')
  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  useEffect(() => {
    const fetchCountryName = async () => {
      try {
        const allCountries = await getCountries()
        const country = allCountries.find((c) => c.id === card.country_id)
        if (country) {
          setCountryName(country.name)
        }
      } catch (error) {
        console.error('Failed to fetch country name', error)
      }
    }

    fetchCountryName()
  }, [card.country_id])

  return (
    <>
      <div className=" w-full px-4  py-5 rounded  bg-blueButton flex flex-col  ">
        <div className=" w-full  flex gap-4">
          <div className=" w-2/12">
            <img
              src={card.image}
              alt={card.title}
              className=" w-full max-h-[200px] h-full rounded border-[1px] border-white "
            />
          </div>
          <div className=" w-11/12 h-full  flex flex-col gap-3 ">
            <div className=" flex gap-4 ">
              <div className=" text-white w-1/6 text-2xl border-r-[1px] border-gray">
                {card.title}
              </div>
              <div className=" text-white flex flex-col w-1/6 items-center border-r-[1px] border-gray">
                <div className=" text-start">Country</div>
                <div className=" text-start">{countryName}</div>
              </div>
              <div className=" text-white flex flex-col w-1/6 items-center border-r-[1px] border-gray">
                <div className=" text-start">Creation Date</div>
                <div className=" text-start">{formatDate(card.created_at)}</div>
              </div>
              <div className=" text-white flex flex-col w-1/6 items-center border-r-[1px] border-gray">
                <div className=" text-start">Last Modified</div>
                <div className=" text-start">{formatDate(card.updated_at)}</div>
              </div>

              <div className=" text-white flex flex-col w-2/6 items-center ">
                <div className=" text-start">Categories</div>
                <div className=" text-start  flex max-w-full flex-wrap justify-center gap-[1px]">
                  {categoryNames?.map((cat, index) => (
                    <div
                      className=" bg-blueButton px-2 border-r-gray border-r-[1px]"
                      key={index}
                    >
                      {cat}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=" w-full text-white text-xs">{card.description}</div>
          </div>
        </div>
        <div className=" w-full flex justify-end">
          <button
            className=" border-[1px] border-white rounded py-1 px-5 text-white hover:scale-110 duration-300 hover:bg-white hover:text-blueButton "
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </div>
      </div>
      <AnimatePresence>
        {edit && (
          <EditCard active={edit} close={() => setEdit(false)} card={card} />
        )}
      </AnimatePresence>
    </>
  )
}

export default CardDetailes
