import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import x from '../../assets/icons/close.svg'
import trash from '../../assets/icons/trash.svg'
import deleteCategory from '../../apis/deleteCategory'

const DeletCategory = ({ active, close, categoty }) => {
  const popupref = useRef(null)
  const [errormsg, setErrormsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handelDelet = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      await deleteCategory(categoty.id)
      window.location.reload()
    } catch (error) {
      setErrormsg(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])
  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <div className=" w-full px-8 py-5 flex flex-col gap-8 ">
          <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
            <div>Delete Category</div>
            <img src={x} onClick={close} className=" cursor-pointer" alt="" />
          </div>
          <div className=" items-center flex flex-col w-full gap-2 justify-center">
            <div className=" bg-red/15 rounded-full p-4">
              <img src={trash} alt="" />
            </div>
            <div className=" text-center text-blueButton">
              Are You Sure You Want To Delete {categoty.title} And All The Cards
              It Contains?
            </div>
          </div>
          {errormsg && (
            <div className=" flex justify-center items-center p-3">
              <div className=" text-center bg-red/20 rounded p-2 text-red">
                {errormsg}
              </div>
            </div>
          )}
          <div className=" w-full flex justify-center items-center gap-8">
            <button
              disabled={loading}
              onClick={handelDelet}
              className=" disabled:bg-red/60 bg-red py-2 px-12 rounded-lg text-white "
            >
              Delete
            </button>

            <button
              onClick={close}
              className=" bg-gray py-2 px-12 rounded-lg text-white "
            >
              Cancel
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default DeletCategory
