import React from 'react'
import { Navigate } from 'react-router-dom'
import Dashboard from '../../pages/Dashboard'
import { useAuth } from '../../hooks/AuthContext'

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
}

export default ProtectedRoute
