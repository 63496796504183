import { motion } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import x from '../../../assets/icons/close.svg'

const ViewCash = ({ active, close, order }) => {
  const popupref = useRef(null)
  console.log('order ', order)
  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', options)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className=" fixed inset-0 bg-overlay/30 w-screen  z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className=" w-1/3 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <div className="w-full">
          <div className=" w-full px-8 py-5 flex flex-col gap-8 ">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Cash On Delivery Order</div>
              <img src={x} onClick={close} className=" cursor-pointer" alt="" />
            </div>
            <div className=" flex-col w-full gap-4 flex justify-center items-center">
              <div className=" flex gap-2 w-full items-center">
                <div className=" font-bold text-darkBlue"> Order Id :</div>
                <div className="text-darkBlue">{order.order_id}</div>
              </div>
              <div className=" flex gap-2 w-full">
                <div className=" font-bold text-darkBlue text-nowrap">
                  Status :
                </div>
                <div className="text-darkBlue">{order.status}</div>
              </div>
              <div className=" flex gap-2 w-full">
                <div className=" font-bold text-darkBlue text-nowrap">
                  Client :
                </div>
                <div className="text-darkBlue">{order.client_name}</div>
              </div>

              <div className=" flex gap-2 w-full items-center">
                <div className=" font-bold text-darkBlue">Total Price</div>
                <div className=" text-darkBlue">
                  {parseFloat(order.total_price).toFixed(2)}$
                </div>
              </div>
              <div className=" flex gap-2 w-full items-center">
                <div className=" font-bold text-darkBlue">Creation Date :</div>
                <div className="text-darkBlue">
                  {formatDate(order.created_at)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ViewCash
