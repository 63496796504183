import React from 'react'
import bg from '../../assets/images/lines.png'
import categorydark from '../../assets/icons/categoriesBlack.svg'
import categorylight from '../../assets/icons/categoriesWhite.svg'
import cardsdark from '../../assets/icons/cardsBlack.svg'
import cardslight from '../../assets/icons/cardsWhite.svg'
import paymentLight from '../../assets/icons/paymentWhite.svg'
import paymentDark from '../../assets/icons/paymentBlack.svg'
import reportsdark from '../../assets/icons/reportsBlack.svg'
import reportslight from '../../assets/icons/reportsWhite.svg'
import notificationlight from '../../assets/icons/notificationWhite.svg'
import notificationdark from '../../assets/icons/notificationsBlack.svg'
import balancedark from '../../assets/icons/balanceBlack.svg'
import balancelight from '../../assets/icons/balanceWhite.svg'
// import messagesdark from '../../assets/icons/messagesBlack.svg'
// import messageslight from '../../assets/icons/messagesWhite.svg'
import settingdark from '../../assets/icons/settingsBlack.svg'
import accountlight from '../../assets/icons/accountWhite.svg'
import accountdark from '../../assets/icons/acocuntBlack.svg'
import settingslight from '../../assets/icons/settingsWhite.svg'
import cashblack from '../../assets/icons/cash.svg'
import cashwhit from '../../assets/icons/cashwhite.svg'
import complanetblack from '../../assets/icons/complane.svg'
import complanetwhite from '../../assets/icons/complanewhite.svg'
import { Link, useLocation } from 'react-router-dom'

const Sidepare = () => {
  const { pathname } = useLocation()
  return (
    <div className="bg-gradient-to-b from-darkBlue to-lightBlue fixed top-0 left-0 w-1/6  h-screen rounded-r-xl">
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: ` -70px 100%`,
        }}
        className="flex flex-col gap-3 justify-center overflow-y-scroll py-8 pl-2 bg-contain bg-no-repeat w-full h-full"
      >
        <Link
          to="/"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img src={pathname === '/' ? categorydark : categorylight} alt="" />
          <span> Card Categories</span>
        </Link>

        <Link
          to="/cards"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/cards'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img src={pathname === '/cards' ? cardsdark : cardslight} alt="" />
          <span> Cards</span>
        </Link>

        <Link
          to="/orders"
          className={`flex gap-2 rounded-l-full p-3 ${
            pathname === '/orders'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img src={pathname === '/orders' ? cashblack : cashwhit} alt="" />
          <span>Orders</span>
        </Link>

        <Link
          to="/account-management"
          className={`flex gap-2 rounded-l-full p-3 ${
            pathname === '/account-management'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={
              pathname === '/account-management' ? accountdark : accountlight
            }
            alt=""
          />
          <span>Account Management</span>
        </Link>

        <Link
          to="/payment-methods"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/payment-methods'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/payment-methods' ? paymentDark : paymentLight}
            alt=""
          />
          <span> Payment Methods</span>
        </Link>

        <Link
          to="/reports"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/reports'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/reports' ? reportsdark : reportslight}
            alt=""
          />
          <span>Reports</span>
        </Link>

        <Link
          to="/notifications"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/notifications'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={
              pathname === '/notifications'
                ? notificationdark
                : notificationlight
            }
            alt=""
          />
          <span>Notifications</span>
        </Link>

        <Link
          to="/balance"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/balance'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/balance' ? balancedark : balancelight}
            alt=""
          />
          <span>Balance</span>
        </Link>

        {/* <Link
          to="/messages"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/messages'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/messages' ? messagesdark : messageslight}
            alt=""
          />
          <span>Messages</span>
        </Link> */}

        <Link
          to="/complaints"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/complaints'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/complaints' ? complanetblack : complanetwhite}
            alt=""
          />
          <span>Complaints</span>
        </Link>

        <Link
          to="/settings"
          className={` flex gap-2 rounded-l-full p-3 ${
            pathname === '/settings'
              ? ' bg-white text-darkBlue'
              : 'bg-transparent text-white'
          }`}
        >
          <img
            src={pathname === '/settings' ? settingdark : settingslight}
            alt=""
          />
          <span>Settings</span>
        </Link>
      </div>
    </div>
  )
}

export default Sidepare
