import React, { useCallback, useEffect, useState } from 'react'
import searsh from '../../assets/icons/searsh.svg'
import filtericon from '../../assets/icons/filter.svg'
import sort from '../../assets/icons/sort.svg'
import categoryicon from '../../assets/icons/categoriesGray.svg'
import Addcategory from '../category/Addcategory'
import { AnimatePresence } from 'framer-motion'
import AddSubCategory from '../subcategory/AddsubCategory'
import { useParams } from 'react-router-dom'
import showAllCategories from '../../apis/showAllCategories'
import showSubCategories from '../../apis/showSubCategories'
import AddCard from '../card/AddCard'
import AddPaymentMethod from '../payment/AddPaymnetMethod'
import AddPackage from '../package/AddPackage'
import AddEmployee from '../emplyees/AddEmployee'
import AddCode from '../code/AddCode'
import AddClient from '../emplyees/AddClient'

const SearshFilter = ({
  type,
  filter,
  setFilter,
  sortType,
  setSortType,
  searchTerm,
  setSearchTerm,
  onCategoryChange,
  selectedCategoryId,
  stockStatus,
  setStockStatus,
}) => {
  const { categoryId, cardId, packageId } = useParams()
  const [categories, setCategories] = useState([])
  const [active, setActive] = useState(false)
  const [noButton, setNoButton] = useState(false)

  const routeNames = {
    category: { id: 1, title: 'Add Category' },
    Cards: { id: 2, title: 'Add Card' },
    subcategory: { id: 3, title: 'Add Sub Category' },
    packages: { id: 4, title: 'Add Package' },
    employees: { id: 5, title: 'Add Employee' },
    clients: { id: 5, title: 'Add Client' },
    code: { id: 11, title: 'Add Code' },
    payment: { id: 6, title: 'Add payment Method' },
    notifications: { id: 7, title: '' },
    earnings: { id: 8, title: '' },
    transfer: { id: 9, title: '' },
    balance: { id: 10, title: '' },
    order: { id: 11, title: '' },
    complaints: { id: 12, title: '' },
    reports: { id: 13, title: '' },
  }

  useEffect(() => {
    if (
      type === 'transfer' ||
      type === 'earnings' ||
      type === 'balance' ||
      type === 'complaints' ||
      type === 'reports' ||
      type === 'notifications' ||
      type === 'order'
    ) {
      setNoButton(true)
    } else {
      setNoButton(false)
    }
  }, [type])

  const fetchCardDetails = useCallback(async () => {
    if (type === 'Cards') {
      try {
        const allCategories = await showAllCategories()
        const categoriesWithSubCategories = await Promise.all(
          allCategories.map(async (category) => {
            const subCategories = await showSubCategories(category.id)
            return { ...category, subCategories }
          })
        )
        setCategories(categoriesWithSubCategories)
      } catch (error) {
        console.error('Failed to fetch categories', error)
      }
    }
  }, [type])

  useEffect(() => {
    fetchCardDetails()
  }, [fetchCardDetails])

  const currentRoute = routeNames[type]

  const renderAddComponent = () => {
    switch (type) {
      case 'category':
        return <Addcategory active={active} close={() => setActive(false)} />
      case 'subcategory':
        return (
          <AddSubCategory
            parentId={categoryId}
            active={active}
            close={() => setActive(false)}
          />
        )
      case 'Cards':
        return (
          <AddCard
            parentId={selectedCategoryId}
            active={active}
            close={() => setActive(false)}
          />
        )
      case 'payment':
        return (
          <AddPaymentMethod active={active} close={() => setActive(false)} />
        )

      case 'packages':
        return (
          <AddPackage
            cardId={cardId}
            active={active}
            close={() => setActive(false)}
          />
        )
      case 'code':
        return (
          <AddCode
            packageId={packageId}
            active={active}
            close={() => setActive(false)}
          />
        )

      case 'employees':
        return <AddEmployee active={active} close={() => setActive(false)} />
      case 'clients':
        return <AddClient active={active} close={() => setActive(false)} />
      default:
        return null
    }
  }

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value
    if (selectedCategoryId !== 'all') {
      const selectedCategory = categories
        .flatMap((cat) =>
          cat.subCategories.length ? cat.subCategories : [cat]
        )
        .find((cat) => cat.id === parseInt(selectedCategoryId))
      onCategoryChange(selectedCategory)
    } else {
      onCategoryChange(null)
    }
  }

  return (
    <>
      <div className="flex gap-4">
        <div
          style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
          className={`${
            type === 'Cards' || type === 'packages'
              ? 'w-1/5'
              : noButton
              ? 'w-1/2'
              : 'w-2/5'
          } rounded`}
        >
          <form className="flex p-3" onSubmit={(e) => e.preventDefault()}>
            <div className="w-2/12" type="submit">
              <img src={searsh} alt="" />
            </div>
            <input
              placeholder="search"
              className="focus:outline-none w-10/12"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>
        </div>
        {type === 'Cards' && (
          <div
            style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
            className={`${noButton ? 'w-1/4' : 'w-[25%]'}  rounded`}
          >
            <form className="flex p-3 gap-2 items-center">
              <div className="w-2/12" type="submit">
                <img src={categoryicon} alt="" />
              </div>
              <div className="text-gray">category</div>
              <select
                className="focus:outline-none w-10/12 text-gray"
                onChange={handleCategoryChange}
                value={selectedCategoryId || 'all'}
              >
                <option value="all">all</option>
                {categories.map((cat) => (
                  <>
                    <option
                      key={cat.id}
                      value={cat.id}
                      disabled={cat.subCategories.length > 0}
                    >
                      {cat.title}
                    </option>
                    {cat.subCategories.map((sub) => (
                      <option key={sub.id} value={sub.id}>
                        - {sub.title}
                      </option>
                    ))}
                  </>
                ))}
              </select>
            </form>
          </div>
        )}

        {type === 'packages' && (
          <div
            style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
            className={`${noButton ? 'w-1/4' : 'w-1/5'}  rounded`}
          >
            <form className="flex p-3 gap-2 items-center">
              <div className="w-2/12" type="submit">
                <img src={filtericon} alt="" />
              </div>

              <select
                className="focus:outline-none w-10/12 text-gray"
                onChange={(e) => setStockStatus(e.target.value)}
                value={stockStatus || 'all'}
              >
                <option value="all">All</option>
                <option value="inStock">In Stock</option>
                <option value="outOfStock">Out of Stock</option>
              </select>
            </form>
          </div>
        )}

        {type !== 'order' && type !== 'reports' && type !== 'notifications' ? (
          <div
            style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
            className={`${
              type === 'Cards' || type === 'packages'
                ? 'w-1/6'
                : noButton
                ? 'w-1/4'
                : 'w-1/5'
            } rounded`}
          >
            <form className="flex p-3">
              <div className="w-2/12" type="submit">
                <img src={filtericon} alt="" />
              </div>
              <select
                className="text-gray focus:outline-none w-10/12"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                {type !== 'employees' && type !== 'clients' && (
                  <option value="All">All</option>
                )}
                <option value="Active">
                  {type === 'complaints'
                    ? 'New'
                    : type === 'clients'
                    ? 'Wholesalers'
                    : 'Active'}
                </option>
                <option value="Inactive">
                  {type === 'code'
                    ? 'Rejected'
                    : type === 'employees'
                    ? 'Trashed'
                    : type === 'complaints'
                    ? 'Close'
                    : type === 'clients'
                    ? 'Retailers'
                    : 'Inactive'}
                </option>
              </select>
            </form>
          </div>
        ) : (
          <></>
        )}

        {type !== 'complaints' && (
          <div
            style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
            className={`${
              type === 'Cards' || type === 'packages'
                ? 'w-1/6'
                : noButton
                ? 'w-1/4'
                : 'w-1/5'
            } rounded`}
          >
            <form className="flex p-3">
              <div className="w-2/12" type="submit">
                <img src={sort} alt="" />
              </div>
              <select
                className="focus:outline-none w-10/12 text-gray"
                value={sortType}
                onChange={(e) => setSortType(e.target.value)}
              >
                <option value="alphabet">
                  {type !== 'reports' && type !== 'order'
                    ? 'Alphabet'
                    : 'price'}
                </option>
                <option value="creationDate">Creation Date</option>
              </select>
            </form>
          </div>
        )}

        {!noButton && (
          <button
            onClick={() => setActive(true)}
            style={{ boxShadow: `1px 4px 10px -5px #1F143D` }}
            className="w-1/5 rounded bg-blueButton text-white"
          >
            {currentRoute.title}
          </button>
        )}
      </div>

      <AnimatePresence>{active && renderAddComponent()}</AnimatePresence>
    </>
  )
}

export default SearshFilter
