import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import x from '../../assets/icons/close.svg'
import photo from '../../assets/icons/phot.svg'
import addPackage from '../../apis/addPackage'

const AddPackage = ({ cardId, active, close }) => {
  const popupref = useRef(null)
  const [error, setError] = useState('')
  const [packageData, setPackageData] = useState({
    title: '',
    quantity: '',
    cardId: cardId || '',
    serialNumber: '',
    isActive: 1,
    outOfStock: 0,
    pricePosClient: '',
    priceClient: '',
    maxNumberToBuyAtOnce: '',
    discount: false,
  })
  const [imageFile, setImageFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleAddPackage = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('title', packageData.title)
    formData.append('count', packageData.quantity)
    formData.append('card_id', packageData.cardId)
    formData.append('is_active', packageData.isActive)
    formData.append('out_of_stock', packageData.outOfStock)
    formData.append('serial_number', packageData.serialNumber)
    formData.append('price_pos_client', packageData.pricePosClient)
    formData.append('price_client', packageData.priceClient)

    formData.append(
      'max_number_to_buy_at_once',
      packageData.maxNumberToBuyAtOnce
    )
    if (imageFile) {
      formData.append('images', imageFile)
    } else {
      setError('Add an Image')
      return
    }

    try {
      setLoading(true)
      await addPackage(cardId, formData)
      window.location.reload()
    } catch (error) {
      console.log('error', error)
      setError(error.response.data.data)
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setPackageData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }))
    setError('')
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))
    setError('')
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setImageFile(file)
    setPreview(URL.createObjectURL(file))

    const inputElement = document.getElementById('image')
    inputElement.focus()
    inputElement.blur()
    setError('')
  }

  const clearFiles = (e) => {
    e.preventDefault()
    setPreview(null)
    setImageFile(null)
    setError('')
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupref.current && !popupref.current.contains(event.target)) {
        close()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupref, close])

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [active])

  return (
    <motion.div
      className="fixed inset-0 bg-overlay/30 w-screen z-40 h-screen grid place-items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        ref={popupref}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="w-1/2 min-h-10 overflow-hidden bg-white rounded-lg flex flex-col justify-center items-center"
      >
        <form onSubmit={handleAddPackage} className="w-full">
          <div className="w-full px-8 py-5 flex flex-col gap-8">
            <div className="flex border-b-[1px] pb-4 justify-between border-b-darkBlue w-full">
              <div>Add New Package</div>
              <img src={x} onClick={close} className="cursor-pointer" alt="" />
            </div>
            <div className="flex gap-4 justify-center items-stretch">
              <div className="w-1/2 flex flex-col gap-4 h-full">
                <div className="rounded-xl p-4 border-[1px] h-1/3 border-b-darkBlue">
                  <label
                    htmlFor="image"
                    className="w-full cursor-pointer rounded-xl relative p-4"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      name="image"
                      id="image"
                      type="file"
                      onChange={handleFileChange}
                      className="hidden w-full focus:outline-none"
                    />
                    {preview ? (
                      <div className="w-full absolute top-2 right-2 z-10 flex justify-end items-center">
                        <img
                          onClick={clearFiles}
                          className="cursor-pointer"
                          src={x}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="flex w-full justify-center items-center">
                        Choose or Drop a File
                      </div>
                    )}
                    <img
                      src={preview || photo}
                      alt="Preview"
                      className={`  w-full h-28 ${
                        preview ? 'object-contain' : 'object-contain'
                      }`}
                    />
                  </label>
                </div>
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <input
                    value={packageData.title}
                    required
                    onChange={handleInputChange}
                    className="w-full focus:outline-none"
                    placeholder="Package Title"
                    name="title"
                    id="title"
                  />
                </div>
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <input
                    value={packageData.quantity}
                    required
                    onChange={handleInputChange}
                    className="w-full focus:outline-none"
                    placeholder="Quantity"
                    name="quantity"
                    id="quantity"
                  />
                </div>
              </div>
              <div className="w-1/2 flex flex-col gap-4 h-full">
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <input
                    value={packageData.serialNumber}
                    required
                    onChange={handleInputChange}
                    className="w-full focus:outline-none"
                    placeholder="Serial Number"
                    name="serialNumber"
                    id="serialNumber"
                  />
                </div>
                <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                  <input
                    value={packageData.maxNumberToBuyAtOnce}
                    required
                    onChange={handleInputChange}
                    className="w-full focus:outline-none"
                    placeholder="Max Number to Buy at Once"
                    name="maxNumberToBuyAtOnce"
                    id="maxNumberToBuyAtOnce"
                  />
                </div>

                <>
                  <div className="w-full flex items-center">
                    <label htmlFor="discount" className="ml-2">
                      Price For retailer
                    </label>
                  </div>
                  <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                    <input
                      value={packageData.priceClient}
                      required
                      onChange={handleInputChange}
                      className="w-full focus:outline-none"
                      placeholder="Price For retailer"
                      name="priceClient"
                      id="priceClient"
                    />
                  </div>
                  <div className="w-full flex items-center">
                    <label htmlFor="discount" className="ml-2">
                      Price For wholesaler
                    </label>
                  </div>
                  <div className="w-full rounded-xl px-4 py-3 border-[1px] border-b-darkBlue">
                    <input
                      value={packageData.pricePosClient}
                      required
                      onChange={handleInputChange}
                      className="w-full focus:outline-none"
                      placeholder="Price For wholesaler"
                      name="pricePosClient"
                      id="pricePosClient"
                    />
                  </div>
                </>
              </div>
            </div>
          </div>
          {error && (
            <div className=" w-full flex justify-center py-3 items-center">
              <span className=" bg-red/20 px-3 py-1 text-red rounded">
                {error}
              </span>
            </div>
          )}
          <button
            disabled={loading}
            className="disabled:bg-darkBlue/50 bg-blueButton text-white text-center p-4 w-full"
          >
            Add
          </button>
        </form>
      </motion.div>
    </motion.div>
  )
}

export default AddPackage
