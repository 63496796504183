import React, { useCallback, useEffect, useState } from 'react'
import SearshFilter from '../components/shared/SearshFilter'
import showAllComplaints from '../apis/showAllComplaints'
import ComplaintTable from '../components/complaints/ComplaintTable'

const Complaints = () => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('All')

  const fetchData = useCallback(async () => {
    try {
      const complaintsData = await showAllComplaints()
      console.log(complaintsData)
      // Sort by creationDate
      const sortedData = complaintsData.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )
      setData(sortedData)
      setFilteredData(sortedData)
    } catch (error) {
      console.error('Failed to fetch', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const filtered = data.filter((complaint) => {
      const statusMatches =
        (filter === 'Active' && complaint.status === 'new') ||
        (filter === 'Inactive' && complaint.status === 'close') ||
        filter === 'All'
      const textMatches = complaint.text
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      return statusMatches && textMatches
    })
    setFilteredData(filtered)
  }, [filter, searchTerm, data])

  return (
    <>
      <div className="text-2xl text-blue-600 mb-4">Complaints</div>
      <SearshFilter
        type="complaints"
        filter={filter}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortType="creationDate"
        setSortType={() => {}}
      />

      <div className="">
        {loading ? (
          <div>loading ... </div>
        ) : (
          <ComplaintTable data={filteredData} />
        )}
      </div>
    </>
  )
}

export default Complaints
