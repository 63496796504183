import axios from 'axios'

const addClient = async (userData) => {
  console.log(userData)
  const formData = new FormData()
  formData.append('email', userData.email)
  formData.append('password', userData.password)
  formData.append('first_name', userData.firstName)
  formData.append('last_name', userData.lastName)
  formData.append('is_active', 1)
  formData.append('type', userData.type)
  formData.append('device', String(userData.deviceToken))
  formData.append('ip_country', '185.107.56.166')
  formData.append('account_balance', '0')

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}systemUsers/createUser`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        },
        withCredentials: true,
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export default addClient
